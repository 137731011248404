if (!Element.prototype.matches) {
  Element.prototype.matches = (Element.prototype as any).msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s:string) {
    let el:any = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

export function getWindowScroll() {
  if('scrollY' in window)
    return window.scrollY;
  return document.body.scrollTop;
}

export function decimalToCSS(val:number) {
  return val*100 + '%';
}