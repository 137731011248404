import {orderBy, flatten} from 'lodash';
import {detectionToEvents, formatFlightNumber} from "../../services/data";
import moment from "moment";
import {showDetectionGap, showDetectionTimestamp} from "../../services/config";
import {formatFromSeconds, formatTimeForFilename, now} from "../../services/time";
import exportFile from "../../services/exportFile";
import DetectionEvent from "../../models/event";
import Detection from "../../models/detection";
import Turnaround from "../../models/turnaround";
import {makeExport} from "../../services/api";

type Row = [string,DetectionEvent,Turnaround?]
type Column = [string,(r:Row)=>string]

const columns:Column[]  = [
  ['Stand ID',([standId]:Row)=>standId],
  ['ID',([id,e]:Row)=>e.id],
  ['Turnaround ID',([id,e,turn]:Row)=>turn?.id || ""],
  ['Flight number (outbound)',([id,e,turn]:Row)=>turn?.outboundFlight ? formatFlightNumber(turn.outboundFlight) : ""],
  ['Origin',([id,e,turn]:Row)=>turn?.inboundFlight?.departureAirport || ""],
  ['Destination',([id,e,turn]:Row)=>turn?.outboundFlight?.arrivalAirport || ""],
  ['Aircraft type',([id,e,turn]:Row)=>(turn?.outboundFlight || turn?.inboundFlight)?.aircraftType || ""],
  ['Type',([id,e]:Row)=>e.label || e.type],
  ['Timestamp',([id,e]:Row)=>moment(e.timestamp).utc().format()]
];

// if(showDetectionTimestamp)
//   columns.push(['Detection timestamp',(e: DetectionEvent)=>moment(e.detectionTimestamp).utc().format()]);

if(showDetectionGap)
  columns.push([
    'Detection gap',
    ([id,e]:Row)=>e.detectionGap || e.detectionGap === 0 ? formatFromSeconds(e.detectionGap, false) : ''
  ]);

columns.push(['Confidence',([id,e]:Row)=>e.confidence ? Math.round(e.confidence * 100) + '%' : '']);

export default async (ids: string[], start: number, end:number) => {
  const chunks: [number,number,string][] = [];

  let current = start;
  do{
    const chunk:[number,number] = [current,Math.min(end,current + 3600*24*1000)];
    ids.forEach(id=>chunks.push([...chunk,id]));
    if(chunk[1] >= end)
      break;
    current = chunk[1];
  }while (true);

  const result: {[key:string]:[Detection[],Turnaround[]]} = {};
  ids.forEach(id=>result[id] = [[],[]]);

  while (chunks.length) {
    console.log(chunks.length);
    const promises = [];
    for(let i=0;i<Math.min(chunks.length,3);i++) {
      let chunk = chunks.pop();
      if(!chunk)
        break;
      let id = chunk[2];
      promises.push(makeExport(id,chunk[0],chunk[1]).then(res=>[id,...res]));
    }

    let tmp = await Promise.all(promises);
    tmp.forEach(row=>{
      let id = row[0]
      result[id][0].push(...row[1])
      result[id][1].push(...row[2])
    });
  }

  const lines = [
    columns.map(c=>c[0]).join(';')
  ];
  ids.forEach(id=> {
    let [detections,turns] = result[id];

    let rows: Row[] = [];

    detections.forEach(d=>{
      let [ev1,ev2] = detectionToEvents(d);
      let turn = turns.find(t=>t.start<=(d.end || now()) && (!t.end || t.end >= d.start));
      if(ev1)
        rows.push([id,ev1,turn]);
      if(ev2)
        rows.push([id,ev2,turn]);

    })

    rows = orderBy(rows,[(r:Row)=>r[1].timestamp],['desc']);
    rows.forEach(row=>{
      let line = columns.map(([name,getValue])=>getValue(row)).join(';');
      lines.push(line);
    })
  })

  let text = lines.join('\n');
  let filename = ids.length > 1 ? 'export_' : ids[0] + '_';
  filename += `${formatTimeForFilename(start)}-${formatTimeForFilename(end)}.csv`;
  exportFile(filename, text);
}
