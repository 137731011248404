import React from "react";
import "./style.scss";
import {connect, useSelector} from "react-redux";
import {
  selectCameras,
} from "../../redux/selectors";
import FrameContainer from "../FrameContainer";

type Props = {
  camera?: string,
  msg?:string,
  hideText?: boolean
}

const FrameUnavailable: React.FC<Props> = ({camera,msg, children,hideText})=> {
  const cameras =  useSelector(selectCameras);

  camera = camera || cameras[0];
  return <FrameContainer camera={camera}>
    <div className={'frame-unavailable'}>
      {!hideText && (
        <div className={'exception'}>
          <div className={'msg'}>
            <i className="fas fa-plane-slash"/>
            {msg || 'HistoricFrame is unavailable'}
          </div>
        </div>
      )}
    </div>
    {children}
  </FrameContainer>
};

export default FrameUnavailable;