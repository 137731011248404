// @ts-ignore
import chroma from "chroma-js";
import update from "immutability-helper";
import _ from "lodash";
import {
  SET_CURRENT_PLANE_TYPE,
  SET_TURNAROUNDS,
  SET_CURRENT_STAND_ID,
  SERVER_UNAVAILABLE,
  SET_RESOLUTION,
  UPDATE_REALTIME_DATA,
  SET_PTS_FOR_PLANE,
  TOGGLE_CONFIDENCE_ON_TIMELINE,
  SET_REPLAY_TIMESTAMP,
  OPEN_FRAME_MODAL,
  SET_CURRENT_TURNAROUND_ID,
} from "./constants";
import ReduxAction from "../models/reduxAction";
import CameraOutage from "../models/cameraOutage";
import Turnaround from "../models/turnaround";
import Detection from "../models/detection";
import {RealtimeState} from "../models/state";

const initialState : RealtimeState = {
  eventsLabels: (window as any).eventsLabels,
  resolution: {},
  pts: {},
  showConfidenceOnTimeline: false,
  outages: [],
  colors: {},
  serverUnavailable: false,
  detections: [],
  allTurnaroundsLoaded: false
};

(function() {
  let keys = _.uniq(Object.keys((window as any).operationsLabels));
  let colors = chroma
    .scale(["#fa7900", "#3e7d95"])
    .mode("lch")
    .colors(keys.length);
  initialState.colors = {};
  keys.forEach((k, i) => (initialState.colors[k] = colors[i]));
})();

export default function(state:RealtimeState = initialState, action: ReduxAction):RealtimeState {
  switch (action.type) {
    case SET_TURNAROUNDS:
      let items = _.orderBy(action.turnarounds, ['start'], ['desc']);
      return {
        ...state,
        turnarounds: items,
        allTurnaroundsLoaded: action.finished
      };
    case SET_CURRENT_STAND_ID:
      return {
        ...state,
        currentTurnaroundId: undefined,
        turnarounds: undefined,
        detections: [],
        outages: [],
        inferenceTimestamp: undefined,
        lastImageTimestamp: undefined,
        allTurnaroundsLoaded: false
      }
    case SET_CURRENT_TURNAROUND_ID:
      return {
        ...state,
        currentTurnaroundId: action.id
      };
    case UPDATE_REALTIME_DATA:
      if(!state.detections)
        state = {...state, detections: []};
      if(!state.outages)
        state = {...state, outages: []};
      state =  updateStateWithData(state,action);
      return state;
    case SET_REPLAY_TIMESTAMP:
      return {...state, replayTimestamp: action.timestamp};
    case SET_CURRENT_PLANE_TYPE:
      state = update(state, {
        planeType: {$set: action.planeType},
      });
      return state;
    case OPEN_FRAME_MODAL:
      return {...state, frameModalData: action.data};
    case SERVER_UNAVAILABLE:
      return {...state, serverUnavailable: action.value};
    case SET_RESOLUTION:
      return update(state,{
        resolution: {
          [action.camera]: {$set: action.value}
        }
      });
    case SET_PTS_FOR_PLANE:
      return update(state,{
        pts: { [action.planeType]: {$set: action.data} }
      });
    case TOGGLE_CONFIDENCE_ON_TIMELINE:
      return {...state, showConfidenceOnTimeline: !state.showConfidenceOnTimeline};
    default:
      return state;
  }
}

function updateStateWithData(state: RealtimeState,action: ReduxAction) {
  const {detections,outages,inferenceTimestamp, lastImageTimestamp,turnarounds,startTs,endTs} = action;

  state = {...state};

  let newDetectionsIds = (detections as Detection[]).map(e=>e.id);
  state.detections = [
    ...state.detections.filter(({start:ts,id})=>(ts<startTs || (endTs && ts > endTs)) && !newDetectionsIds.includes(id)),
    ...detections
  ];

  let newOutagesIds = (outages as CameraOutage[]).map(e=>e.id);
  state.outages = [
    ...state.outages.filter(({start:ts,id})=>(ts<startTs || (endTs && ts > endTs)) && !newOutagesIds.includes(id)),
    ...outages
  ];

  let turnsIds = (turnarounds as Turnaround[]).map(t=>t.id);
  state.turnarounds = [
    ...(state.turnarounds || []).filter(({id})=>!turnsIds.includes(id)),
    ...turnarounds
  ];
  state.turnarounds =  _.orderBy(state.turnarounds, ['start'], ['desc']);

  if(inferenceTimestamp){
    state.inferenceTimestamp = inferenceTimestamp;
  }
  if(lastImageTimestamp)
    state.lastImageTimestamp = lastImageTimestamp;

  return state;
}
