import React, {Component} from "react";
import Hammer from 'hammerjs';
import {debounce} from 'lodash';

import './style.scss';
import {ReactComponent as MapSvg} from './map.svg';
import {connect, ConnectedProps} from "react-redux";
import {selectStands} from "../../redux/selectors";
import Gate from "./Gate";
import MapToolbar from "../MapToolbar";
import {SET_CURRENT_STAND_ID} from "../../redux/constants";
import {createStructuredSelector} from "reselect";
import Stand from "../../models/stand";
import {State} from "../../redux/store";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {LodashDebounce} from "lodash/fp";
import {history} from "../../services/router";

const LOW_DETAILED_CLASS_NAME = 'low-detailed';

type ReduxProps = {
  stands: Stand[]
}

const mapStateToProps = createStructuredSelector<State,ReduxProps>({
  stands: selectStands,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State,any,Action>) => ({dispatch});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

type MapState = {
  showGates: boolean
}

type ViewBox = [number,number,number,number];

class Map extends Component<Props,MapState> {
  ref = React.createRef<SVGSVGElement>();
  initialViewBox: ViewBox = [0,0,0,0];
  pinchInitialBox?: ViewBox;
  hammer?: HammerManager;
  moveInitialViewBox?: ViewBox;
  isPinching: boolean = false;
  pinchCenter? : [number,number];
  zoomCenter?: [number,number] | null;
  keepPinchData?: ReturnType<LodashDebounce>;
  zoomInitialBox?: ViewBox | null;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      showGates: false,
    };


    this.onWheel = this.onWheel.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onPinchStart = this.onPinchStart.bind(this);
    this.onPinch = this.onPinch.bind(this);
    this.onPanStart = this.onPanStart.bind(this);
    this.onPinchEnd = this.onPinchEnd.bind(this);
    this.onPan = this.onPan.bind(this);
  }

  componentDidMount() {
    this.props.dispatch({
      type: SET_CURRENT_STAND_ID,
      id: null
    });
    this.initialViewBox = this.getViewBox();
    this.setState({showGates:true});

    const svg = this.ref.current;
    if(!svg)
      return;
    svg.addEventListener('gesturestart', this.onPinchStart as EventListener);
    svg.addEventListener('gesturechange', this.onPinch as any);
    svg.addEventListener('wheel', this.onWheel,false);

    this.hammer = new Hammer.Manager(svg,{
      recognizers: [
        [Hammer.Pinch, { enable: true }],
        [Hammer.Pan, { enable: true }]
      ]
    });
    this.hammer.on('panstart',this.onPanStart);
    this.hammer.on('pan',this.onPan);
    this.hammer.on('pinch',this.onPinch);
    this.hammer.on('pinchstart',this.onPinchStart);
    this.hammer.on('pinchend',this.onPinchEnd);

    this.checkLabelsVisibility();
  }

  componentWillUnmount() {
    if(this.hammer)
      this.hammer.destroy();

    const svg = this.ref.current;
    if(svg) {
      svg.removeEventListener('gesturestart', this.onPinchStart as EventListener);
      svg.removeEventListener('gesturechange', this.onPinch as any);
      svg.removeEventListener('wheel', this.onWheel);
    }
  }

  onPanStart() {
    this.moveInitialViewBox =  this.getViewBox();
  }

  onPan(ev: HammerInput) {
    if(this.isPinching)
      return;
    const svg = this.ref.current;
    if(!svg || !this.moveInitialViewBox)
      return;
    let factor = (svg.getScreenCTM() as DOMMatrix).a;
    let box: ViewBox = [...this.moveInitialViewBox];
    box[0] -= ev.deltaX/factor;
    box[1] -= ev.deltaY/factor;
    this.setViewBox(box);
  }

  onPinchStart(ev: HammerInput | MouseEvent) {
    ev.preventDefault();
    let point = "center" in ev ? [ev.center.x,ev.center.y] : [ev.clientX,ev.clientY];
    this.pinchInitialBox = this.getViewBox();
    this.pinchCenter = this.getSvgCords(point[0],point[1]);
    this.isPinching = true;
  }

  onPinchEnd(ev: HammerInput) {
    setTimeout(()=>this.isPinching = false,500)
  }

  onPinch(ev: HammerInput) {
    ev.preventDefault();
    const {pinchCenter,pinchInitialBox} = this;
    if(ev.scale === 1 || !pinchCenter || !pinchInitialBox) {
      //for some reason sometimes it's 1 in the end
      return;
    }
    let x = (pinchCenter[0] - pinchInitialBox[0])/pinchInitialBox[2];
    let y = (pinchCenter[1] - pinchInitialBox[1])/pinchInitialBox[3];
    let width = pinchInitialBox[2]/ev.scale;
    let height = pinchInitialBox[3]/ev.scale;

    const newBox: ViewBox = [
      pinchCenter[0] - x*width,
      pinchCenter[1] - y*height,
      width,
      height
    ];
    this.setViewBox(newBox);
  }

  onWheel(ev: WheelEvent) {
    ev.preventDefault();

    let currentBox = this.getViewBox();
    if(!this.zoomCenter) {
      this.zoomInitialBox = [...currentBox];
      this.zoomCenter = this.getSvgCords(ev.clientX,ev.clientY);
      this.keepPinchData = debounce(()=>{
        this.zoomInitialBox = null;
        this.zoomCenter = null;
      },500);
    }else if(this.keepPinchData){
      this.keepPinchData();
    }

    const {zoomInitialBox} = this;
    if(!zoomInitialBox)
      return;
    let x = (this.zoomCenter[0] - zoomInitialBox[0])/zoomInitialBox[2];
    let y = (this.zoomCenter[1] - zoomInitialBox[1])/zoomInitialBox[3];
    let width = currentBox[2];
    let height = currentBox[3];

    const k = 0.95;
    if((ev.ctrlKey && ev.deltaY > 0) || (!ev.ctrlKey && ev.deltaY < 0)) {
      //zoom out
      width /= k;
      height /= k;
    }else{
      //zoom in
      width *= k;
      height *= k;
    }

    const newBox: ViewBox = [
      this.zoomCenter[0] - x*width,
      this.zoomCenter[1] - y*height,
      width,
      height
    ];
    this.setViewBox(newBox);
  }

  onMouseMove(ev: MouseEvent) {
    if(ev.buttons !== 1)
      return;

    let prevPoint = this.getSvgCords(ev.clientX - ev.movementX,ev.clientY - ev.movementY);
    let currentPoint = this.getSvgCords(ev.clientX,ev.clientY);
    const deltaX = currentPoint[0] - prevPoint[0];
    const deltaY = currentPoint[1] - prevPoint[1];

    let newBox = this.getViewBox();
    newBox[0] -= deltaX;
    newBox[1] -= deltaY;
    this.setViewBox(newBox);
  }

  getSvgCords(x:number,y:number) :[number,number]{
    const svg = this.ref.current as SVGSVGElement;
    const point = svg.createSVGPoint();
    point.x = x;
    point.y = y;
    const ctm = svg.getScreenCTM() as DOMMatrix;
    const inverse = ctm.inverse();
    const p2 = point.matrixTransform(inverse);
    return [p2.x,p2.y];
  }

  getViewBox():[number,number,number,number] {
    const boxValue = this.ref?.current?.getAttribute('viewBox');
    if(!boxValue)
      return [0,0,0,0];
    return boxValue.split(' ').map(v=>parseFloat(v)) as [number,number,number,number];
  }

  setViewBox(box: ViewBox) {
    const svg = this.ref.current;
    const initialBox = this.initialViewBox;

    if(!svg)
      return;

    if(box[2] > initialBox[2] || box[3] > initialBox[3]){
      box[2] = initialBox[2];
      box[3] = initialBox[3]
    }

    const minX = initialBox[0];
    const maxX = initialBox[0] + initialBox[2];
    const minY = initialBox[1];
    const maxY = initialBox[1] + initialBox[3];
    if(box[0] < minX)
      box[0] = minX;
    if(box[1] < minY)
      box[1] = minY;
    if(box[0] + box[2] > maxX)
      box[0] = maxX - box[2];
    if(box[1] + box[3] > maxY)
      box[1] = maxY - box[3];

    this.checkLabelsVisibility();
    svg.setAttribute('viewBox',box.join(' '));
  };

  checkLabelsVisibility() {
    const svg = this.ref.current;
    if(!svg)
      return;
    let factor = (svg.getScreenCTM() as DOMMatrix).a;
    if(factor < 0.65)
      svg.classList.add(LOW_DETAILED_CLASS_NAME);
    else
      svg.classList.remove(LOW_DETAILED_CLASS_NAME);
  }

  render() {
    const {stands} = this.props;
    const {showGates} = this.state;

    return (
      <>
        <MapToolbar/>
        <div className={'map'} >
          <MapSvg
            ref={this.ref}
            preserveAspectRatio={'xMidYMid meet'}
            onMouseMove={this.onMouseMove as any}
          />
        </div>
        {showGates && stands.map((s)=><Gate stand={s} key={s.id}/>)}
      </>
    );
  }
}

export default connector(Map);