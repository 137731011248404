import React, {useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import {createPortal} from 'react-dom'

import GatePopup from "./GatePopup";
import {useStand} from "./hooks";
import { mapGates } from "../../services/config";
import Stand from "../../models/stand";

const planeSize = [26,28];
const textHeight = 7.3;
const textMargin = [3,2];
const gateSize = {
  's' : [30,60],
  'm' : [40,60],
  'l' : [58,60],
};
const bkPath = "M0,2c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2v53.2c0,0.5-0.2,1-0.6,1.4l-2.8,2.8c-0.4,0.4-0.9,0.6-1.4,0.6H4.8c-0.5,0-1-0.2-1.4-0.6l-2.8-2.8C0.2,56.2,0,55.7,0,55.2V2z";
const headerPath = "M30,11H0V2c0-1.1,0.9-2,2-2h26c1.1,0,2,0.9,2,2V11z";
const planePath = "M12.1,27.4c-0.7-0.7-1.1-2.3-1.1-4.7v-5.8L0.3,9.6L0.2,9.5C0.1,9.4,0,9.3,0,9.1V7.6c0-0.1,0.1-0.3,0.2-0.4C0.3,7,0.5,7,0.7,7l10.3,3.7v-6C8.6,3.4,7.3,2.6,7.2,2.5C7.1,2.4,7,2.3,7,2.1V0.6c0-0.1,0.1-0.3,0.2-0.4C7.3,0,7.5,0,7.7,0L13,1.5L18.3,0c0.2-0.1,0.4,0,0.5,0.1C18.9,0.3,19,0.4,19,0.6l0,1.6c0,0.1-0.1,0.3-0.2,0.4c-0.1,0.1-1.4,0.9-3.9,2.2v6L25.3,7c0.2-0.1,0.4,0,0.6,0.1C25.9,7.3,26,7.4,26,7.6v1.6c0,0.1-0.1,0.3-0.2,0.4c0,0-0.1,0.1-0.1,0.1l-10.8,7.3v5.8c0,2.4-0.4,4-1.1,4.7c-0.3,0.3-0.6,0.4-0.9,0.4C12.7,27.8,12.4,27.7,12.1,27.4z";

type GateProps = {
  stand: Stand
}

const Gate: React.FC<GateProps> = ({stand}) => {
  const [showPopup,setShowPopup] = useState(false);
  const status = useStand(stand.id);
  const history = useHistory();
  const ref = useRef<SVGGElement>(null);

  const config = mapGates[stand.id];
  if(!config)
    return null;
  // @ts-ignore
  let cellSize = gateSize[config.size];

  let cellMove = `translate(${config.cords[0]},${config.cords[1]})`;
  let cellRotate = '';
  let planeTransform = `translate(${(cellSize[0]-planeSize[0])/2},${(cellSize[1] - planeSize[1])/2 + 5})`;
  let textCords = {x:textMargin[0], y: textHeight + textMargin[1]};
  let textTransform = '';
  let bkTransform = `scale(${cellSize[0]/gateSize['s'][0]},1)`;
  if(config.placement === 'bottom') {
    cellRotate = `rotate(${180},${cellSize[0]/2},${cellSize[1]/2}) `;
    textTransform = `rotate(180,${cellSize[0]/2},${textMargin[1] + textHeight/2})`
  }else if(config.placement === 'left') {
    cellRotate = `rotate(${-90},${cellSize[0]/2},${cellSize[1]/2}) `;
    textTransform = `rotate(180,${cellSize[0]/2},${textMargin[1] + textHeight/2})`
  }

  const svg = document.querySelector('.map svg');
  if(!svg)
    return null;

  return (
    <>
      {createPortal(
        <g
          transform={cellMove}
          onClick={()=>history.push('/'+stand.id)}
          ref={ref}
          onMouseEnter={()=>setShowPopup(true)}
          onMouseLeave={()=>setShowPopup(false)}
          className={'gate'}
        >
          <g transform={cellRotate}>
            <path className="cell-bk" d={bkPath} transform={bkTransform}/>
            <path className="cell-header" d={headerPath} transform={bkTransform}/>
            {status.turnaround && <path className="plane" d={planePath} transform={planeTransform}/>}
            <text {...textCords} className={'gate-label'} transform={textTransform}>{config.label}</text>
          </g>
        </g>,
        svg
      )}
      {showPopup && status.turnaround && (
        <GatePopup turnaround={status.turnaround} target={ref.current as SVGSVGElement} standId={stand.id}/>
      )}
    </>
  )
}

export default Gate;