import React from "react";
import Tour, {ReactourStep} from 'reactour'

const stepFactory= (content:string) => () => (
  <div className={'step'}>
    {content}
  </div>
);

const allSteps: ReactourStep[] = [
  {
    selector: '.modal .cameras',
    content: stepFactory('Switch between the cameras capturing the event.'),
    position: 'top'
  },
  {
    selector: '.modal .timestamp-info label',
    content: stepFactory('Event name.'),
    position: 'top'
  },
  {
    selector: '.modal .timestamp-info span',
    content: stepFactory('Date and time of the detection of the event.'),
    position: 'top'
  },
  {
    selector: '.modal .close-btn',
    content: stepFactory('Close the popup and return to the main view.'),
    position: 'top'
  },
  {
    selector: '.modal .frame-container',
    content: stepFactory('Snapshot of the detected event. The detection area is highlighted with a red rectangle.'),
    position: 'top'
  },
  {
    selector: '.modal .prev',
    content: stepFactory('Switch to the previous frame (you can also use the left arrow on the keyboard).'),
    position: 'top'
  },
  {
    selector: '.modal .prev10',
    content: stepFactory('Rewind to several frames back.'),
    position: 'top'
  },
  {
    selector: '.modal .toolbar span',
    content: stepFactory('Date and time of the shown snapshot.'),
    position: 'top'
  },
  {
    selector: '.modal .next10',
    content: stepFactory('Fast forward to several frames ahead.'),
    position: 'top'
  },
  {
    selector: '.modal .next',
    content: stepFactory('Switch to the next frame (you can also use the right arrow on the keyboard).'),
    position: 'top'
  },

  {
    selector: '.dropdown-btn.stand-dropdown',
    content:  stepFactory('Use this dropdown list to choose between the available stands.'),
    position: 'bottom',
  },
  {
    selector: '.logout',
    content: stepFactory('Log out from the system. After pressing this button, you will be asked for a confirmation. Once you confirm, you will need to use your credentials to log in again.'),
    position: 'bottom'
  },

  {
    selector: '.time-format-select',
    content: stepFactory('Switch between your local browser time and Zulu time.'),
    position: 'bottom',
  },
  {
    selector: '.timeline',
    content: stepFactory('The Gantt chart displays all the detected operations of the turnaround. On the top the time axis is displayed. Clicking on an event bar opens a screenshot of the detected event. Clicking on the time axis forces the main video window to show the snapshot of the apron at the moment corresponding to the area of the Gantt chart where the user has clicked.'),
    position: 'bottom',
  },
  {
    selector: '.cameras',
    content: stepFactory('Switch between the cameras observing the same stand.'),
    position: 'left',
  },
  {
    selector: '.frame-container',
    content: stepFactory('The screen displays the video of the turnaround. The default mode is showing the real time feed. Clicking on the Gantt chart or selecting the past turnaround switches to showing historical footage.'),
    position: 'left',
  },
  {
    selector: '.turnaround-info',
    content: stepFactory('Information about the turnaround obtained from Assaia system and the airport API.'),
    position: 'bottom',
  },
  {
    selector: '.map',
    content: stepFactory('Use scroll to zoom and drag to move.'),
    position: 'left',
  },
  {
    selector: '.user-dropdown',
    content: stepFactory('Use this menu to log out or to change your timezone.')
  }
]

export default allSteps;