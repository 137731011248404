import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './style.scss';
import {useSelector} from "react-redux";
import {selectCurrentStandId} from "../../redux/selectors";

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default function ContactForm({onSuccess}) {
  const [loading, setLoading] = useState(false);
  const formRef = useRef();
  const standId = useSelector(selectCurrentStandId);

  const send = (ev)=>{
    if(ev)
      ev.preventDefault();

    let email = formRef.current[0].value;
    let firstName = formRef.current[1].value;
    let lastName = formRef.current[2].value;

    if(!email) {
      alert("Please enter your email.");
      return;
    }

    if(!validateEmail(email)) {
      alert("Please enter valid email email.");
      return;
    }

    if(!firstName && !lastName) {
      alert("Please enter your name.");
      return;
    }

    const data =  {
      email,
      message: `First name: ${firstName};Last name: ${lastName}; Stand: ${standId}`
    };

    setLoading(true);

    fetch('https://formspree.io/moqkzvgj', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    }).then(()=>{
      setLoading(false);
      let tag = document.createElement('img');
      tag.style.display = 'none';
      tag.width = 1;
      tag.height = 1;
      tag.src = 'https://px.ads.linkedin.com/collect/?pid=695858&conversionId=2901585&fmt=gif';
      document.body.appendChild(tag);
      alert("Thank you, we will get back to you as soon as possible!");
      onSuccess();
    });
  };

  return (
      <div className={'contact-form'}>
        <h1>Do you want to experience how our ApronAI Turnaround Control can help your company?</h1>

        <div className={'description'}>
          Please enter your contact details and we will get back to you to create your personal trial run.
        </div>

        <form onSubmit={send} ref={formRef}>
          <label>email</label>
          <input placeholder={'Example@mail.com'} name={'email'}/>
          <br/>
          <label>First name</label>
          <input placeholder={'John'} name={'first-name'}/>
          <br/>
          <label>Last name</label>
          <input placeholder={'Doe'} name={'last-name'}/>
        </form>
        <div className={'security-text'}>
          We will never share our data with other parties
        </div>
        <a className={classnames('send-btn',{disabled: loading})} onClick={()=>!loading && send()}>
          send
        </a>
      </div>
  )
}