import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router'
import {connect, ConnectedProps} from "react-redux";
import Home from "../Home";
import PreLoader from "../PreLoader";
import AuthCallback from "../AuthCallback";
import ErrorCallback from "../ErrorCallback";
import SignRedirect from "./signRedirect";
import {createStructuredSelector} from "reselect";
import {selectCurrentTurnaround, selectUser} from "../../redux/selectors";
import {gaRefreshRate} from "../../services/config";
import {history} from "../../services/router";
import DashboardOff from "../DashboardOff";
import ErrorMessage from "../ErrorMessage";
import {User} from "oidc-client";
import {State} from "../../redux/store";
import Turnaround from "../../models/turnaround";

type  AppState = {
  failed: boolean
}

type ReduxProps = {
  user?: User
  turn?: Turnaround | null
}
const mapStateToProps = createStructuredSelector<State,ReduxProps>({
  user: selectUser,
  turn:selectCurrentTurnaround
});

const connector = connect(mapStateToProps, null);

class App extends Component<ReduxProps,AppState> {
  state = {
    failed:false
  };

  componentDidMount() {
    setInterval(()=>{
      this.sendGAEvent();
    },gaRefreshRate);
    this.sendGAEvent();
  }
  
  sendGAEvent() {
    try{
      if(this.props.user)
        (window as any).ga.getAll()[0].send("event", "User",this.props.user.profile.email);
    }catch(er){
      console.log("failed to send ga event");
    }
  }

  isSigned() {
    let {user} = this.props;
    return user && !user.expired;
  }

  render() {

    if(this.state.failed)
      return <ErrorMessage/>;

    if(this.isSigned())
      return <ConnectedRouter history={history}>
        <Home/>
      </ConnectedRouter>;

    return(
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={'/off'} component={DashboardOff}/>
          <Route path={'/auth/callback'} component={AuthCallback}/>
          <Route path={'/error'} component={ErrorCallback}/>
          <Route path={'/'} render={()=><SignRedirect onFail={()=>this.setState({failed:true})}/>}/>
          <PreLoader/>;
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default connector(App);