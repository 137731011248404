export default function (filename:string, text: string) {
  if(window.navigator.msSaveBlob) {
    console.log("Save like IE");
    let blobObject = new Blob([text]);
    window.navigator.msSaveBlob(blobObject, filename);
    return;
  }

  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename.replace(/\s/g,'_'));
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}