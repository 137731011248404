import React, { Component } from 'react';
import userManager from "../../services/userManager";
import { backupUrlPath } from '../../services/router';

export default class SignRedirect extends Component {
  componentDidMount() {
    backupUrlPath();
    userManager.signinRedirect().catch(()=>{
      this.props.onFail();
    })
  }
  
  render() {
    return null;
  }
}