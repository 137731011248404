import React from "react";
import moment from "moment";
import { useSelector } from "react-redux"

import { selectTimeFormat } from "../../redux/selectors"
import { UTC } from "../../services/time";

type Props = {
  format: string
  time: number
}

const TimeFormatter: React.FC<Props> = ({format, time}) =>{
  const timeFormat = useSelector(selectTimeFormat);
  let obj = moment(time);
  if(timeFormat === UTC)
    obj = obj.utc();
  return <>{obj.format(format)}</>;
};

export default TimeFormatter;