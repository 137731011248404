import React from 'react';
import './style.scss';
import {useSelector} from "react-redux";
import {selectCurrentTurnaround} from "../../redux/selectors";
import TimeFormatter from "../TimeFormatter";

const TurnaroundTiming: React.FC = ()=> {
  const turnaround = useSelector(selectCurrentTurnaround);
  if(!turnaround)
    return null;
  const {inboundFlight, outboundFlight} = turnaround;

  const renderValue = (val: number | null | undefined) => (
    val ? <TimeFormatter format='HH:mm' time={val}/> : '-'
  );

  const renderDelay = (scheduled: number | null | undefined,actual: number | null | undefined) => {
    if(!actual || !scheduled)
      return null;
    let delay = actual - scheduled;
    if(!delay)
      return null;
    let str = Math.abs(delay) < 60000 ? `${Math.round(delay/1000)}s` : `${Math.round(delay/60000)}m`;
    if(delay > 0)
      return '+' + str;
    return str;
  };

  return (
    <div className={'turnaround-timing'}>
      <div className={'card'}>
        <h4>landing</h4>
        <div className={'card-row'}>
          <label>slt</label>
          <span>{renderValue(inboundFlight?.scheduledLandingTime)}</span>
        </div>
        <div className={'card-row'}>
          <label>alt</label>
          <span className={'big'}>{renderValue(inboundFlight?.actualLandingTime)}</span>
        </div>
        <div className={'card-row delay'}>
          <span>{renderDelay(inboundFlight?.scheduledLandingTime,inboundFlight?.actualLandingTime)}</span>
        </div>
      </div>

      <div className={'card'}>
        <h4>in block</h4>
        <div className={'card-row'}>
          <label>sibt</label>
          <span>{renderValue(inboundFlight?.scheduledInBlockTime)}</span>
        </div>
        <div className={'card-row'}>
          <label>aibt</label>
          <span className={'big'}>{renderValue(inboundFlight?.actualInBlockTime)}</span>
        </div>
        <div className={'card-row delay'}>
          <span>{renderDelay(inboundFlight?.scheduledInBlockTime,inboundFlight?.actualInBlockTime)}</span>
        </div>
      </div>

      <div className={'card'}>
        <h4>off block</h4>
        <div className={'card-row'}>
          <label>sobt</label>
          <span>{renderValue(outboundFlight?.scheduledOffBlockTime)}</span>
        </div>

        <div className={'pobt-row-wrapper'}>
          <div className={'card-row'}>
            <label>pobt</label>
            <span className={'big'}>{renderValue(turnaround.pobt)}</span>
          </div>

          <div className={'card-row delay'}>
            <span>{renderDelay(outboundFlight?.scheduledOffBlockTime,turnaround.pobt)}</span>
          </div>
        </div>

        <div className={'card-row tobt-row'}>
          <label>tobt</label>
          <span>{renderValue(outboundFlight?.targetOffBlockTime)}</span>
        </div>

        <div className={'card-row'}>
          <label>aobt</label>
          <span>{renderValue(outboundFlight?.actualOffBlockTime)}</span>
        </div>
        <div className={'card-row delay'}>
          <span>{renderDelay(outboundFlight?.scheduledOffBlockTime,outboundFlight?.actualOffBlockTime)}</span>
        </div>
      </div>

      <div className={'card'}>
        <h4>take off</h4>
        <div className={'card-row'}>
          <label>stot</label>
          <span>{renderValue(outboundFlight?.scheduledTakeOffTime)}</span>
        </div>
        <div className={'card-row'}>
          <label>atot</label>
          <span>{renderValue(outboundFlight?.actualTakeOffTime)}</span>
        </div>
        <div className={'card-row delay'}>
          <span>{renderDelay(outboundFlight?.scheduledTakeOffTime,outboundFlight?.actualTakeOffTime)}</span>
        </div>
      </div>
    </div>
  )
};

export default TurnaroundTiming;