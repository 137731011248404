import React, {useState} from "react";
import classNames from "classnames";
import {useSelector} from "react-redux";
import {selectCurrentTurnaround} from "../../redux/selectors";
import "./style.scss";

type Props = {
  title: string,
  className?: string
}

const STORAGE_KEY = "section_collapsed";

const Section: React.FC<Props> = ({title,children,className}) => {
  const [collapsed,setCollapsed] = useState(!!parseInt(localStorage.getItem(STORAGE_KEY+title) || "0"));
  const toggle = () => {
    const val = !collapsed;
    setCollapsed(val);
    localStorage.setItem(STORAGE_KEY+title,val ? "1":"0");
  };

  const turnaround = useSelector(selectCurrentTurnaround);

  return (
    <section
      style={{opacity: turnaround && turnaround.authorized ? 1 : 0.4}}
      className={classNames('section',className,{collapsed})}
    >
      <a className={'section_title'} onClick={toggle}>
        <span>{title}</span>
        <i className={'triangle'}>
          {collapsed ? <i className="fas fa-caret-left"/> : <i className="fas fa-caret-down"/>}
        </i>
      </a>
      {!collapsed && children}
    </section>
  );
}

export default Section;