import {flatten, sortBy,orderBy, capitalize} from "lodash";
import {now, toMilliSecondsOrNull} from "./time";
import {reportError} from "./logger";
import {frozen, ignoreMinOperationConfidenceOps, minOperationConfidence, turnaroundsStartTimestamp,mergeOperations as mergeOperationsFlag} from "./config";
import {camelCaseKeys, convertObjUtcToMillseconds} from "./data";
import {OTHERS_GROUP} from "./constants";
import Detection from "../models/detection";
import Turnaround from "../models/turnaround";
import CameraOutage from "../models/cameraOutage";
import BBox from "../models/bbox";

export function parseTurnarounds(turnarounds: any[], standId: string):Turnaround[] {
  turnarounds = turnarounds.map(parseTurnaround);
  if(!turnaroundsStartTimestamp)
    return turnarounds;

  let ts = typeof turnaroundsStartTimestamp === 'object' ? turnaroundsStartTimestamp[standId] : turnaroundsStartTimestamp;
  ts = ts || 0;
  return turnarounds.filter(i => i.start >= ts);
}

export function parseTurnaround(data:any):Turnaround {
  const res:any = {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    pushbackMaxSpeed: data.pushback_speed_max,
    videos: data.replays || {},
    authorized: data.authorized !== false,
    inboundFlight: data.inbound_flight && camelCaseKeys(data.inbound_flight),
    outboundFlight: data.outbound_flight && camelCaseKeys(data.outbound_flight),
    pobt: toMilliSecondsOrNull(data.pobt)
  };

  //tmp hack for empty flight info
  if(res.inboundFlight && !Object.keys(res.inboundFlight).length)
    res.inboundFlight = null;
  if(res.outboundFlight && !Object.keys(res.outboundFlight).length)
    res.outboundFlight = null;

  if(res.inboundFlight){
    res.inboundFlight = convertObjUtcToMillseconds(res.inboundFlight);
    res.inboundFlight.scheduledInBlockTime = res.inboundFlight.scheduledDateTime;
  }
  if(res.outboundFlight) {
    res.outboundFlight = convertObjUtcToMillseconds(res.outboundFlight);
    res.outboundFlight.scheduledOffBlockTime = res.outboundFlight.scheduledDateTime;
  }
  return res;
}

export function parseDetections(detections: Detection[]) {
  detections = detections.map(parseDetection);
  if(window.hiddenOperations)
    detections = detections.filter((d:Detection)=>!window.hiddenOperations.includes(d.type));
  if(minOperationConfidence)
    detections = detections.filter((d:Detection)=>
      ignoreMinOperationConfidenceOps.includes(d.type) || (d.confidence && d.confidence >= minOperationConfidence));
  if(mergeOperationsFlag)
    detections = mergeOperations(detections);

  return detections;
}

export function parseDetection(data:any):Detection {
  let group = window.groupsMeta.find(g=>g.operations.includes(data.op_name));
  let detection:Detection = {
    id: data.id,
    confidence: data.confidence,
    startConfidence: data.start_confidence,
    endConfidence: data.end_confidence,
    startDetectionGap: data.start_detection_gap,
    endDetectionGap: data.end_detection_gap,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    startType: data.start_type,
    endType: data.end_type,
    startLabel: window.eventsLabels[data.start_type] || data.start_type,
    endLabel: window.eventsLabels[data.end_type] || data.end_type,
    type: data.op_name,
    label: window.operationsLabels[data.op_name] || data.op_name,
    bbox: data.bbox && parseBBox(data.bbox),
    group: group ? group.key : OTHERS_GROUP
  };

  return detection;
}

export function parseCameraOutage(data:any) :CameraOutage {
  return {
    id: data.id,
    start: toMilliSecondsOrNull(data.start_ts) as number,
    end: toMilliSecondsOrNull(data.end_ts),
    camera: data.camera_id
  }
}

function parseBBox(data:any):BBox {
  return {
    box: data.box,
    camera: data.camera_id
  };
}

export function mergeOperations(detections: Detection[]) {
  let mergeableKeys = flatten(Object.values(window.mergeableOperations));
  let newOperations = [...detections.filter(o => !mergeableKeys.includes(o.type))];

  for (let key in window.mergeableOperations) {
    let detectionsToMerge: Detection[] = detections.filter(d => window.mergeableOperations[key].includes(d.type));
    if (!detectionsToMerge.length)
      continue;

    detectionsToMerge = sortBy(detectionsToMerge, d => d.start);

    let detection;
    while (detection = detectionsToMerge.shift()) {
      let range = [detection.start, detection.end || now()];
      let newDetection = {...detection};

      for (let detection2 of [...detectionsToMerge]) {
        let range2 = [detection2.start, detection2.end || now()];
        if (!(range[0] <= range2[1] && range[1] >= range2[0]))
          break;

        if (range2[1] > range[1]) {
          newDetection.id = `${detection.id}-${detection2.id}`;
          newDetection.endConfidence = detection2.endConfidence;
          newDetection.endDetectionGap = detection2.endDetectionGap;
          newDetection.end = detection2.end;
          newDetection.endType = detection2.endType;
          newDetection.endLabel = detection2.endLabel;
        }
        detectionsToMerge = detectionsToMerge.filter(o => o.id !== detection2.id);
      }
      newOperations.push(newDetection);
    }
  }
  return newOperations;
}

export function validateAndFix(obj:any, fields:[string,any,boolean,(val:any)=>any][]) {
  fields.forEach(([key, schema, required, parse]) => {
    let value = obj[key];
    if (parse)
      value = parse(value);
    if (required && value == null) {
      let error = new Error(`Missing "${key}".`);
      console.error(error);
      reportError(error);
      throw error;
    }
    if (!required && value == null)
      return;

    let valid = schema.isValidSync(value);
    if (valid)
      return;
    let error = new Error(`Invalid value. ${key} = ${obj[key]}`);
    console.error(error);
    reportError(error);
    obj[key] = null;
    if (required) {
      throw error;
    }
  });

  return obj;
}