import React from "react";
import { CallbackComponent } from "redux-oidc";
import {useHistory} from "react-router-dom";

import userManager from "../../services/userManager";
import {reportError} from "../../services/logger";
import PreLoader from '../PreLoader';
import {getUrlPathBackup} from "../../services/router";

const RELOADED_AFTER_AUTH_ERROR_KEY = 'RELOADED_AFTER_AUTH_ERROR_KEY';

const AuthCallback: React.FC = () => {
  const history = useHistory();

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={()=>{
        localStorage.setItem(RELOADED_AFTER_AUTH_ERROR_KEY,"");
        let path = getUrlPathBackup();
        history.push(path || '/');
      }}
      errorCallback={error=>{
        reportError(error);
        if(localStorage.getItem(RELOADED_AFTER_AUTH_ERROR_KEY) !== '1') {
          localStorage.setItem(RELOADED_AFTER_AUTH_ERROR_KEY,'1');
          window.location.pathname = "";
        }
        history.push("/error",{error: error.message});
      }}>
      <PreLoader/>
    </CallbackComponent>
  )
}

export default AuthCallback;
