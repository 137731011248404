import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './style.scss';
import ContactForm from "./form";
import MobileFormPanel from "./mobileForm";

export default function ContactFormPanel() {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(()=>{
    setTimeout(()=>setCollapsed(false),15000)
  },[]);

  let isMobile = window.innerWidth < 900;

  useEffect(()=>{
    if(isMobile)
      return;

    const cont = document.querySelector('.turnaround');
    if(cont && collapsed)
      document.querySelector('.turnaround').style.paddingRight = '42px';
    else if(cont)
      document.querySelector('.turnaround').style.paddingRight = '310px';
  }, [collapsed]);

  if(isMobile)
    return <MobileFormPanel/>;

  return (
    <div className={classnames('contact-form-panel',{collapsed})}>
      <a className={'toggle-btn'} onClick={()=>setCollapsed(!collapsed)}>
        {!collapsed && <i className={'fal fa-angle-double-right'}/>}
        {collapsed && <i className={'fal fa-angle-double-left'}/>}
        <span>CONTACT</span>
      </a>

      {!collapsed && (
        <ContactForm onSuccess={()=>setCollapsed(true)}/>
      )}
    </div>
  )
}