import React, {PropsWithChildren, useContext, useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import classnames from 'classnames';

import {selectAspectRatio} from "../../redux/selectors";
import './style.scss';

export type ZoomContextValue = {
  zoomed: boolean,
  toggle: ()=>void
}
export const ZoomContext = React.createContext<ZoomContextValue | null>(null);

type Props = {
  camera: string,
  className?: string
}

const FrameContainer : React.FC<Props>=(props:PropsWithChildren<Props>)=> {
  const {camera,children,className} = props;
  const ratio = useSelector(selectAspectRatio)[camera] || 1.78;
  const zoomContext = useContext(ZoomContext);
  const ref = useRef<HTMLDivElement>(null)

  useEffect(()=>{
    let active = true;
    const fn = ()=> {
      if(!active)
        return;
      const el = ref.current;
      if(el) {
        const rect = el.getBoundingClientRect();
        const height = rect.width/ratio;
        el.style.minHeight = height + 'px';
      }
      window.requestAnimationFrame(fn);
    }
    window.requestAnimationFrame(fn);

    return ()=> {active = false;}
  },[ratio]);

  return (
    <div className={classnames('frame-container',className)} ref={ref}>
      {children}
      {zoomContext && (
        <a className={'zoom-btn'} onClick={zoomContext.toggle}>
          <i className={classnames("far",zoomContext.zoomed ? "fa-compress-alt" : "fa-expand-alt")}/>
        </a>
      )}
    </div>
  )
};

export default FrameContainer;