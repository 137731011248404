import axios, {AxiosInstance} from "axios";
import store from "../redux/store";
import {selectAuthHeader} from "../redux/selectors";
import {requestTimeout} from "./config";
import {serverUnavailable} from "../redux/actions";
import {ADD_STANDS} from "../redux/constants";
import {reportError} from "./logger";

const instance: AxiosInstance = axios.create({
  timeout: requestTimeout,
  transformRequest: [function (data, headers) {
    const header = selectAuthHeader(store.getState());
    headers['Authorization'] = header;

    if(data){
      headers['Content-Type'] = 'application/json';
      data = JSON.stringify(data);
    }
    return data;
  }],
});

function onError(er:any) {
  if(er.code === 418){
    //With no stands only exception will be displayed, unless header could be displayed
    store.dispatch(serverUnavailable(false));
    store.dispatch({
      type: ADD_STANDS,
      stands: null
    });
    return Promise.reject(er);
  }

  store.dispatch(serverUnavailable(true));
  reportError(er);
  return Promise.reject(er);
}

instance.interceptors.request.use(function (config) {
  return config;
}, onError);

instance.interceptors.response.use(function (response) {
  if(response.headers['content-type'] === "text/html"){
    const error = new Error("wrong resp format (html instead of json)");
    onError(error);
    throw error;
  }
  store.dispatch(serverUnavailable(false));
  return response;
}, onError);

export default instance;