import React, {ReactElement} from 'react';
import classnames from 'classnames';
import {createPortal} from 'react-dom'
import './style.scss';

type Props = {
  onClose: ()=>void
  className?: string,
  style?: React.CSSProperties
}

const Modal: React.FC<Props> = ({children,onClose,className,style}) => {
  const root = document.getElementById('root');
  if(!root)
    return null;
  return createPortal(
    <div className={classnames(['modal-container', className])} onClick={() => onClose()}>
      <div className={"modal"} onClick={ev => ev.stopPropagation()} style={style}>
        {children}
      </div>
    </div>
  ,root)
};

export default Modal;