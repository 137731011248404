import { createUserManager } from 'redux-oidc';
import Oidc from "oidc-client";

import {openID} from "./config";

const config = {
  redirect_uri: window.location.origin + "/auth/callback",
  response_type: "code",
  scope: "openid",
  post_logout_redirect_uri: window.location.origin,
  ...openID
};

const userManager = createUserManager(config);

Oidc.Log.logger = console;

export default userManager;