import React, {Component, useEffect} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import {Redirect, Route} from "react-router-dom";
import classnames from 'classnames';

import {loadStands, initImgToken} from "../../redux/actions";
import {
  selectServerUnavailable,
  selectStands,
  selectImgToken,
  selectShowExportModal, selectTopAlert
} from "../../redux/selectors";
import Header from "../Header"
import Loader from "../Spinner";
import Stand from "../Stand";
import './style.scss';
import {isMobile, isWindows} from "../../services/platform";
import {disableMap, enableSidebar} from "../../services/config";
import {startSleepCheck} from "../../services/time";
import {Switch} from "react-router-dom";
import SupportButton from "../SupportButton";
import ExportModal from "../ExportModal";
import ReactDOM from "react-dom";
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../ErrorMessage";
import Sidebar from "../Sidebar";
import Map from "../Map";
import Logo from "../Logo";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";
import {COPYRIGHT} from "../../services/constants";

const Home: React.FC = ()=> {
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();
  const stands = useSelector(selectStands);
  const serverUnavailable = useSelector(selectServerUnavailable);
  const imgToken = useSelector(selectImgToken);
  const showExportModal = useSelector(selectShowExportModal);
  const topAlert = useSelector(selectTopAlert);

  useEffect(()=>{
    dispatch(loadStands());
    dispatch(initImgToken());
    startSleepCheck();
  },[]);

  if (serverUnavailable && !stands)
    return <ErrorMessage/>;

  if (!stands || !imgToken)
    return <div className={'home'}>
      <Loader/>
    </div>;

  if (!stands.length)
    return <ErrorMessage msg={'No active stands found.'}/>;

  return (
    <>
      {topAlert ? <div className={'warning'}>{topAlert}</div> : null}
      <ErrorBoundary>
        <div className={classnames({'win-os': isWindows},'home')}>
          <SupportButton/>
          {!enableSidebar && (
            <div className={'footer-logo'}>
              <Logo/>
              <span>{COPYRIGHT}</span>
            </div>
          )}

          {showExportModal && <ExportModal/>}
          <Header/>
          {enableSidebar && <Sidebar/>}
          <Switch>
            <Route path={'/'} exact>
              {(disableMap || isMobile) ? <Redirect to={"/" + stands[0].id}/> : <Map/>}
            </Route>
            <Route path={'/:standId?'}>
              <Stand/>
            </Route>
          </Switch>
        </div>
      </ErrorBoundary>
    </>
  )
};

export default Home;