import React, {useContext} from 'react';
import './style.scss';
import Logo from "../Logo";
import {SidebarContext} from "./index";
import classNames from 'classnames';
import {COPYRIGHT} from "../../services/constants";

const SidebarFooter: React.FC = ()=> {
  const {collapsed} = useContext(SidebarContext);

  return (
    <div className={classNames('sidebar-footer',{collapsed})}>
      <Logo sm={collapsed}/>
      {!collapsed && <span>{COPYRIGHT}</span>}
    </div>
  )
}

export default SidebarFooter;