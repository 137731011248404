import React from 'react';
import './style.scss';
import Dropdown, {DropdownItem} from "../Dropdown";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {
  selectCurrentStand,
  selectCurrentStandId,
  selectStands,
} from "../../redux/selectors";

const StandSelector: React.FC = () => {
  const stands = useSelector(selectStands);
  const currentStand = useSelector(selectCurrentStand);
  const currentStandId = useSelector(selectCurrentStandId);
  const standDropdownTitle = currentStand ? currentStand.label : 'All gates';
  const history = useHistory();

  return (
    <Dropdown title={standDropdownTitle} align={'right'} className={'stand-dropdown'}>
      {stands.map(stand =>
        <DropdownItem
          key={stand.id}
          active={currentStandId === stand.id}
          onSelect={() => history.push('/' + stand.id)}
        >
          {stand.label}
        </DropdownItem>)}
    </Dropdown>
  )
}

export default StandSelector;