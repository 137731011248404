import React,{Component} from "react";
import './style.scss';
import classnames from 'classnames';
import {default as BBoxType}  from "../../models/bbox";

type Props = {
  bbox:BBoxType,
  color?: string,
  label?: string
}

type State = {
  showLabel: boolean,
  labelInside: boolean
}

export default class BBox extends Component<Props,State> {
  state = {
    showLabel: false,
    labelInside: false
  };

  ref = React.createRef<HTMLDivElement>();

  componentDidMount() {
    if(!this.props.label)
      return;

    let cont = this.ref.current as HTMLDivElement;
    let parentRect = (cont.parentElement as HTMLDivElement).getBoundingClientRect();
    let boxRect = cont.getBoundingClientRect();

    if(boxRect.top - parentRect.top < 20)
      this.setState({labelInside:true});

    this.setState({showLabel: true});
  }

  static decimalToCSS(value: number) {
    return value * 100 + '%'
  };

  render() {
    let {bbox:{box},color,label} = this.props;
    let {showLabel, labelInside} = this.state;

    if (box[2] - box[0] === 1. && box[3] - box[1] === 1.) {
      return null;
    }

    let st: React.CSSProperties = {
      left: BBox.decimalToCSS(box[0]),
      top: BBox.decimalToCSS(box[1]),
      width: BBox.decimalToCSS(box[2] - box[0]),
      height: BBox.decimalToCSS(box[3] - box[1])
    };
    if(color)
      st.borderColor = color;

    return <div className={'bbox'} style={st} ref={this.ref}>
      {showLabel && <span className={classnames({inside: labelInside})} style={{backgroundColor: color}}>{label}</span>}
    </div>
  }
}