import {useEffect, useState} from "react";
import {now} from "./time";

export default function useAutoRefresh(delay = 1000) {
  const [time,setTime] = useState(now());

  useEffect(()=>{
    const intervalId = setInterval(()=>setTime(Date.now()),delay);

    return ()=>{
      clearInterval(intervalId);
    }
  },[])
  return time;
}