import moment from "moment";
import store from "../redux/store"
import {selectTimeFormat} from "../redux/selectors";

function pad(num: number) {
  return ("0"+num).slice(-2);
}

export const UTC = 'UTC';
export const LOCAL = 'LOCAL';

export const timeFormats = [
  [LOCAL, 'Local'],
  [UTC, 'UTC (Z)']
];

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function formatFromSeconds(secs: number, addHours=true) {
  secs = Math.floor(secs);
  let minutes = Math.floor(secs / 60);
  secs = secs%60;
  let hours = Math.floor(minutes/60)
  minutes = minutes%60;
  let str = pad(minutes)+":"+pad(secs);
  if(addHours)
    str = pad(hours) + ":" + str;
  return str;
}

function getTimeObject(ts: number) {
  let time = moment(ts);
  if(selectTimeFormat(store.getState()) === UTC)
    time = time.utc();
  return time;
}

export function formatTime(time:number,date = true ,seconds = true) {
  let format = "";
  if(date)
    format = "YYYY-MM-DD ";
  format += "HH:mm";
  if(seconds)
    format += ":ss";
  return getTimeObject(time).format(format);
}

export function formatTimeForFilename(time:number) {
  let timeObj = getTimeObject(time);
  return timeObj.format("YYYY-MM-DD_HH-mm");
}

export var timeOffset = 0;
export function now() {
  return Date.now() + timeOffset;
}

export function setTimeOffset(offset: number) {
  timeOffset = offset;
}

export function formatTimeDiff(relativeTs: number, absoluteTs:number) {
  let delay = Math.ceil(relativeTs/1000) - Math.ceil(absoluteTs/1000);

  if(delay >= 0)
    return '0s';

  let delayUnit;

  if(Math.abs(delay) < 300)
    delayUnit = 's';
  else if(Math.abs(delay) < 180*60){
    delayUnit = 'min';
    delay = Math.round(delay/60);
  }else {
    delayUnit = 'h';
    delay = Math.round(delay/3600)
  }
  return delay + delayUnit;
}

export function toMilliSecondsOrNull(t: string | null): number | null {
  if(!t)
    return null;
  let parsedTs = parseFloat(t);
  return !isNaN(parsedTs) ? parsedTs * 1000 : null;
}

const SLEEP_DELAY = 40000;
export function startSleepCheck() {
  let ts = Date.now();
  setInterval(()=>{
    let current = Date.now();
    let delta = current - ts;
    if(delta > SLEEP_DELAY){
      window.location.reload();
    }else
      ts = current;
  },1000)
}