import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {loadRealtimeData} from "../../redux/actions";
import {
  selectTurnarounds,
} from "../../redux/selectors";
import { frozen, updateRate } from "../../services/config";

import {now} from "../../services/time";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";
type Timeout = NodeJS.Timeout;

//TODO convert into hook
const StandUpdater:React.FC = ()=> {
  const [counter,setCounter] = useState(0);
  const turnarounds = useSelector(selectTurnarounds) || [];
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();

  useEffect(()=>{
    let mounted = true;
    let timerId: Timeout;
    let turn = turnarounds[0];
    let startTs = turn ? turn.start : now();
    dispatch(loadRealtimeData(startTs)).finally(()=>{
      if(!mounted)
        return;
      if(!frozen)
        timerId = setTimeout(()=>setCounter(counter+1), updateRate);
    });

    return ()=> {
      clearTimeout(timerId);
      mounted = false;
    }
  },[counter]);

  return null;
}

export default StandUpdater;
