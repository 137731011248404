import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import Tour, {ReactourStep} from 'reactour'
import allSteps from "./steps";
import './style.scss';


function preventDefault(e: Event) {
  // debugger;
  e.preventDefault();
}

type Props = {
  onClose: ()=>void;
}

const GuideTour:React.FC<Props> = ({onClose})=>{
  const [current, setCurrent] = useState(0);

  const onDocumentClick = useCallback((ev)=>{
    if(!ev.target.closest('.tour')){
      onClose();
    }
  },[]);

  useEffect(()=>{
    window.addEventListener('touchmove',preventDefault,{passive:false});
    document.addEventListener('click',onDocumentClick);

    return ()=>{
      window.removeEventListener('touchmove',preventDefault);
      document.removeEventListener('click',onDocumentClick);
    }
  },[]);

  const onTourStepChanged = (index:number)=> {
    const step = steps[index];
    const target = step.selector && document.querySelector(step.selector);
    if(target)
      target.scrollIntoView();
    setCurrent(index);
  };

  let steps = allSteps.filter(step=>step.selector && document.querySelector(step.selector));
  if(document.querySelector('.modal'))
    steps = steps.filter(s=>s.selector && s.selector.indexOf('.modal')>=0);
  const key = steps.map(s=>s.selector).join(); //reset tour if steps changed

  return (
    <Tour
      steps={steps}
      getCurrentStep={onTourStepChanged}
      isOpen={true}
      className={classNames('tour',{finished:current === steps.length-1})}
      key={key}
      nextButton={current === steps.length-1 ? <span>Finished</span> : <span> Next <i className="far fa-arrow-right"/></span>}
      prevButton={<span><i className="far fa-arrow-left"/> Back</span>}
      onRequestClose={()=>onClose()}>
      <h2>
        TIP #{current+1}:
        <a onClick={()=>onClose()}>
          <i className="fal fa-times"/>
        </a>
      </h2>
    </Tour>
  );
}

export default GuideTour;