import React from "react";
import { withRouter } from 'react-router-dom'
import Modal from '../Modal';

const component = (props) => {
  if(!props.location.state)
    return window.location='/';

  let er = props.location.state && props.location.state.error;
  let msg;

  if((er && er.includes("in the future")) || (er && er.includes("in the past")))
    msg = "Your local time is incorrect.\n Please synchronize your local time with time server\n and try again.";
  else
    msg = "Authentication error.";

  return (
    <Modal className="error" onClose={() => window.location = '/'}>
      {msg}
      <a onClick={() => window.location = '/'}>Try again</a>
    </Modal>
  );
};

export default withRouter(component);