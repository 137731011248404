import {createSelector} from 'reselect'
import {
  selectReplayTimestamp,
  selectDetections,
  selectCurrentTurnaround,
  selectPtsForCurrentPlaneType,
  selectPtsEnabled,
  selectTurnarounds,
  selectRealtimeState,
  selectCameras,
  selectCurrentTurnaroundId,
  selectInferenceTimestamp
} from "../../redux/selectors";
import _ from "lodash";
import {now} from "../../services/time";
import {OTHERS_GROUP} from "../../services/constants";
import {breakDetections} from "../../services/config";
import {State} from "../../redux/store";
import Detection from "../../models/detection";
import Turnaround from "../../models/turnaround";

const PADDING_MULTIPLIER = 0.05;
const MIN_DISPLAY_PERIOD = 20 * 60 * 1000;
export const PTS_MULTIPLIER = 60*1000;
export const MAX_DETECTION_LAG = 60*2*1000;

export type DetectionEvent = Detection & {original: Detection};

const selectPtsAvailable = createSelector(selectPtsEnabled,selectPtsForCurrentPlaneType,(pts,ptsMisses)=>pts&&ptsMisses);

const selectDetectionsForTimeline = createSelector(selectDetections,items=>{
  let [detections, detectionsToBreak] = _.partition(items,i=>!breakDetections.includes(i.type));
  let events:([DetectionEvent,DetectionEvent] | DetectionEvent)[] = detectionsToBreak.map(detection=>{
    let ev1:DetectionEvent = {...detection, original:detection};
    ev1.type = ev1.startType;
    ev1.label = ev1.startLabel;
    ev1.end = ev1.start;
    ev1.original = detection;

    if(!detection.endType)
      return ev1;

    let ev2: DetectionEvent = {...detection, original:detection};
    ev2.type = ev2.endType as string;
    ev2.label = ev2.endLabel;
    ev2.start = ev2.end as number;

    return [ev1,ev2];
  });
  let res: (DetectionEvent | Detection)[] = [...detections , ..._.flatten(events)];
  res = res.filter(i=>i.label);
  return res;
});

export const selectBounds = createSelector<State,Turnaround | undefined | null,number | undefined, Detection[], boolean, any, Turnaround[] | undefined, [number,number]>(
  selectCurrentTurnaround,
  selectReplayTimestamp,
  selectDetectionsForTimeline,
  selectPtsAvailable,
  selectPtsForCurrentPlaneType,
  selectTurnarounds,
  (turnaround,currentTimestamp,detectionsItems,ptsAvailable,pts,turnarounds) => {
    if(!turnaround) {
      turnaround = turnarounds?.length ? turnarounds[0] : null;
      const end = now() + 5*60*1000;
      return turnaround ? [turnaround.end as number, end] : [now()-MIN_DISPLAY_PERIOD,end]
    }
    if(!turnaround.authorized) {
      return [now()-MIN_DISPLAY_PERIOD,now() + 5*60*1000]
    }

    // 2 cases:
    // 1) turn is shorter than average -> show at least mean turn period
    // 2) turn is longer than average but has not ended yet -> show the whole turn
    const start = turnaround.start;
    let itemsEnd = detectionsItems?.length ? Math.max(...detectionsItems.map(e => e.end || 0)) : now();

    let minEnd = start + MIN_DISPLAY_PERIOD;
    if(ptsAvailable){
      const duration = pts.duration;
      minEnd = start + duration*PTS_MULTIPLIER;
    }

    let end = turnaround.end
      ? turnaround.end
      : Math.max(now(), itemsEnd, minEnd);

    const padding = (end - start)*PADDING_MULTIPLIER;

    return [start - padding, end + padding];
  }
);

export const selectTimelineTimestamp = (state: State) =>  selectCurrentTurnaroundId(state) ? selectReplayTimestamp(state) : now();

export const selectTimelineData = createSelector<State,Detection[],([string,Detection[][]])[]>(selectDetectionsForTimeline,(detections: Detection[])=> {
  let groups : ([string,Detection[]])[] =_.sortBy(window.groupsMeta,'order').map(g=>[g.key,detections.filter(d=>d.group === g.key)]);

  let noGroupdDetections = detections.filter(d=>d.group === OTHERS_GROUP);
  if(noGroupdDetections.length)
    groups.push([OTHERS_GROUP, noGroupdDetections]);

  return groups.map(([group, items]) => {
    items = _.sortBy(items, 'start');
    let result = _.groupBy(items, 'type');
    return [group, Object.values(result)]
  });
});

export const selectCameraOutages = createSelector(
  selectRealtimeState,
  selectBounds,
  selectInferenceTimestamp,
  selectCameras,
  selectCurrentTurnaroundId,
  (state,bounds,inferenceTs,cameras,turnId)=>{
    if(!inferenceTs)
      return [];
    let items = state.outages.filter(o=>o.start < bounds[1] && (!o.end || o.end > bounds[0]));
    if(turnId)
      return items;

    cameras.forEach(camera=>{
      if(now()-inferenceTs[camera] < MAX_DETECTION_LAG)
        return;
      if(items.find(i=>i.camera === camera && !i.end))
        return;

      items.push({start:inferenceTs[camera],camera,id: camera+'_fake'});
    });
    return items;
  });