import React, {ChangeEvent, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {createStructuredSelector} from "reselect";
import InputMask from "react-input-mask";

import './style.scss';
import {
  selectTimeFormat
} from "../../redux/selectors";
import {UTC} from "../../services/time";
import moment from "moment";
import classnames from 'classnames';

function formatDate(ts:number,timeFormat:string) {
  if(timeFormat !== UTC)
    return new Date(ts);

  let utcOffset = (new Date()).getTimezoneOffset() * 60*1000;
  let res = new Date(ts + utcOffset);
  res.setSeconds(0);
  return res;
}

function parseDate(ts: Date,timeFormat: string) {
  if(timeFormat !== UTC)
    return ts.getTime();

  let utcOffset = (new Date()).getTimezoneOffset() * 60*1000;
  return ts.getTime() - utcOffset;
}

type Props = {
  onChange: (ts:number)=>void
  value:number
  min?: number
  max?: number
  disabled?: boolean
  className?: string
}

const DateTimeInput: React.FC<Props> = ({onChange,value,min,max,disabled, className}) => {
  const timeFormat = useSelector(selectTimeFormat);
  const date = formatDate(value,timeFormat);
  const [timeStr,changeTimeStr] = useState(moment(date).format('HH:mm'));

  useEffect(()=>{
    changeTimeStr(moment(date).format('HH:mm'));
  },[value]);

  const validateAndSave = (ts: number) => {
    if(min)
      ts = Math.max(ts,min);
    if(max)
      ts = Math.min(ts,max);
    changeTimeStr(moment(ts).format('HH:mm'));
    onChange(ts);
  };

  const onDateChange = (val: Date) => {
    val.setHours(date.getHours());
    val.setMinutes(date.getMinutes());
    validateAndSave(parseDate(val,timeFormat));
  };

  const onTimeChange = (ev: ChangeEvent) => {
    let str = (ev.target as HTMLInputElement).value || "00:00";
    str = str.replace(/_/g,'0');
    let hours = parseInt(str.split(":")[0]);
    let minutes = parseInt(str.split(":")[1]);

    hours = Math.max(hours,0);
    hours = Math.min(hours,24);

    minutes = Math.max(minutes,0);
    minutes = Math.min(minutes,59);

    date.setHours(hours);
    date.setMinutes(minutes);
    validateAndSave(parseDate(date,timeFormat));
  };

  return <div className={classnames('datetime-input', className)}>
    <DatePicker
      selected={new Date(date)}
      onChange={onDateChange}
      minDate={min ? new Date(min) : null}
      maxDate={max ? new Date(max) : null}
      portalId="root"
      disabled={disabled}
      dateFormat={'dd MMM yyyy'}/>
      -
    <InputMask
      mask={'99:99'}
      value={timeStr}
      className={'time'}
      disabled={disabled}
      // maskPlaceholder={'0'}
      onChange={ev=>changeTimeStr(ev.target.value)}
      onBlur={onTimeChange}
    />
  </div>
};

export default DateTimeInput;