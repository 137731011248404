import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import classnames from "classnames";

import './style.scss';
import {useLocation} from "react-router-dom";
import Logo from "../Logo";
import {selectCurrentStandId, selectReplayTimestamp} from "../../redux/selectors";
import {SIDEBAR_VISIBLE_STORAGE_KEY} from "../../services/constants";
import SidebarHeader from "./header";
import {SidebarContext} from "./index";

const SidebarToolbar: React.FC = ()=> {
  const standId = useSelector(selectCurrentStandId);
  const {allStands,alerts,toggleAllStands,collapsed} = useContext(SidebarContext);

  if(!alerts)
    return null;

  let allCount = alerts.length;
  let currentCount = alerts.filter(i=>i.standId === standId).length;

  if(collapsed)
    return (
      <div className={'sidebar_toolbar collapsed'}>
        <a className={'tab'}>
          <label>{allStands ? 'All' : ''}</label>
        </a>
      </div>
    )

  return (
    <div className={'sidebar_toolbar'}>
      <a className={classnames('tab',{active: allStands})} onClick={()=>toggleAllStands(true)}>
        <label>All gates</label>
        <span className={'badge'}>{allCount}</span>
      </a>
      {standId && (
        <a className={classnames('tab',{active: !allStands})} onClick={()=>toggleAllStands(false)}>
          <label>Current</label>
          <span className={'badge'}>{currentCount}</span>
        </a>
      )}
      {/*<a className={'fas fa-search'}/>*/}
    </div>
  )
}

export default SidebarToolbar;