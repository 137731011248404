import React, {useEffect, useState, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory,useParams} from "react-router-dom";
import classNames from "classnames";

import Spinner from "../Spinner";
import {
  selectTurnarounds,
  selectCurrentTurnaround,
  selectCurrentStandId,
  selectFrameModalData,
  selectCurrentTurnaroundId,
  selectLastImageTimestamp,
} from "../../redux/selectors";
import Timeline from "../Timeline";
import TurnaroundStream from "../TurnaroundStream";
import TurnaroundVideo from "../TurnaroundVideo";
import FrameModal from "../FrameModal";
import {
  searchTurn,
  setCurrentTurnaround
} from "../../redux/actions";
import "./style.scss";
import TurnaroundInfo from "../TurnaroundInfo/container";
import ErrorBoundary from "../ErrorBoundary";
import TurnaroundAlerts from "../TurnaroundAlerts";
import { ZoomContext } from '../FrameContainer';
import {EMPTY_APRON_LABEL, UNAUTHORIZED_TURN_SORRY} from "../../services/constants";
import {ThunkDispatch} from "redux-thunk";
import {State} from "../../redux/store";
import {Action} from "redux";
import FrameUnavailable from "../FrameUnavailable";
import Section from "../Section";
import TurnaroundTiming from "../TurnaroundTiming";
import {showTurnaroundTimingPanel} from "../../services/config";

function useTurnaroundId() {
  const dispatch: ThunkDispatch<State,any,Action> = useDispatch();
  const turnarounds = useSelector(selectTurnarounds);
  const {turnId} = useParams<RouterParams>();
  const history = useHistory();
  const standId = useSelector(selectCurrentStandId);

  useEffect(()=>{
    history.push(`/${standId}/${turnarounds[0].id}`);
  },[turnId])

  useEffect(()=>{
    if(!turnarounds)
      return;
    if(!turnId){
      dispatch(setCurrentTurnaround(turnId));
      return;
    }
    const turn = turnarounds.find(t=>t.id === turnId);
    if(turn && !turn.end && turnarounds.indexOf(turn) === 0) {
      history.push(`/${standId}`);
      return;
    }
    if(!turn){
      dispatch(searchTurn(turnId)).then((turn)=>{
        if(turn && turn.authorized)
          dispatch(setCurrentTurnaround(turnId));
        else
          history.push(`/${standId}`);
      }).catch(()=>history.push(`/${standId}`));
    }else{
      if(turn.authorized)
        dispatch(setCurrentTurnaround(turnId));
      else
        history.push(`/${standId}`);
    }
  },[turnId]);
}

type RouterParams = {
  turnId?:string
}

const Turnaround: React.FC = () => {
  useTurnaroundId();
  const ref = useRef<HTMLDivElement>(null);
  const frameModalData = useSelector(selectFrameModalData);
  const turnaround = useSelector(selectCurrentTurnaround);
  const lastImageTs = useSelector(selectLastImageTimestamp);
  const {turnId} = useParams<RouterParams>();
  const [zoomed, setZoomed] = useState(true);

  //sync is from url and id in store
  let turnIdFromStore = useSelector(selectCurrentTurnaroundId);
  if((turnId !== turnIdFromStore) || (turnaround && !turnaround.loaded) || !lastImageTs)
    return <Spinner/>;

  return (
    <div className={classNames('turnaround',{'video-zoomed':zoomed})} ref={ref}>
      {turnaround && turnaround.authorized && <TurnaroundInfo/>}
      {turnaround && !turnaround.authorized && <div className={'empty-apron'}>{UNAUTHORIZED_TURN_SORRY}</div>}
      {!turnaround && <div className={'empty-apron'}>{EMPTY_APRON_LABEL}</div>}

      <div className={'video-cell'}>
        <ZoomContext.Provider value={{toggle: ()=>setZoomed(!zoomed), zoomed}}>
          {!turnIdFromStore && (!turnaround || turnaround.authorized) && <TurnaroundStream/>}
          {turnIdFromStore && turnaround && turnaround.authorized && <TurnaroundVideo/>}
          {turnaround && !turnaround.authorized && <FrameUnavailable hideText={true}/>}
          {turnaround && turnaround.authorized && <TurnaroundAlerts/>}
        </ZoomContext.Provider>
      </div>

      <div className={'sections'}>
        {showTurnaroundTimingPanel && turnaround &&  (
          <Section title={'Flight information'} className={'timing-section'}>
            <TurnaroundTiming/>
          </Section>
        )}

        <Section title={'Progress chart'} className={'timeline-section'}>
          <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
            <Timeline/>
          </ErrorBoundary>
        </Section>
      </div>
      {frameModalData && <FrameModal/>}
    </div>
  );
}

export default Turnaround;