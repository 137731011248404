import {useDispatch, useSelector} from "react-redux";
import {
  selectReplayTimestamp,
  selectCurrentTurnaroundId,
  selectShowConfidenceOnTimeline,
  selectInferenceTimestamp
} from "../../redux/selectors";
import React, {MouseEventHandler, useContext, useRef} from "react";
import {openFrameModal} from "../../redux/actions";
import {now} from "../../services/time";
import classnames from "classnames";
import {TimelineContext} from "./index";
import {DetectionEvent, MAX_DETECTION_LAG} from "./selectors";
import Detection from "../../models/detection";

type Props = {
  detection: Detection | DetectionEvent,
  onMouseEnter: MouseEventHandler,
  onMouseLeave: MouseEventHandler,
}

const DetectionBar: React.FC<Props> = ({detection,onMouseEnter,onMouseLeave}) => {
  const timeline = useContext(TimelineContext);
  const replayTs = useSelector(selectReplayTimestamp);
  const inferenceTs = useSelector(selectInferenceTimestamp);
  const turnId = useSelector(selectCurrentTurnaroundId);
  const ref = useRef<HTMLDivElement>(null);
  const isReplay = !!useSelector(selectCurrentTurnaroundId);
  const showConfidence = useSelector(selectShowConfidenceOnTimeline);
  const dispatch = useDispatch();
  if(!timeline)
    return null;
  const {getLeftFromTimestamp} = timeline;
  let {start,confidence} = detection;
  const end = detection.end || now();

  const left = getLeftFromTimestamp(start)*100 + '%';
  const width = (getLeftFromTimestamp(end) - getLeftFromTimestamp(start))*100 + '%';
  let fillWidth: string | number = '100%';

  if(replayTs && isReplay && replayTs > start && replayTs < end){
    fillWidth = (replayTs-start)*100/(end-start) + '%';
  }else if(replayTs && isReplay && replayTs<=start){
    fillWidth = 0;
  }else if(!turnId && !detection.end && detection.bbox && inferenceTs) {
    let detectionTs = inferenceTs[detection.bbox.camera];
    if(now() - detectionTs > MAX_DETECTION_LAG){
      fillWidth = 100*(detectionTs - detection.start)/(now()-detection.start) + '%'
    }
  }

  const onClick = (ev: React.MouseEvent) => {
    ev.stopPropagation();
    if(!ref.current)
      return;
    let {left,width} = ref.current.getBoundingClientRect();

    let timestamp = start + (end-start)*(ev.clientX - left)/width;

    dispatch(openFrameModal({
      // @ts-ignore
      detection: detection.original || detection,
      timestamp
    }));
  };

  return (
    <div
      style={{left,width}}
      className={classnames('detection-bar',{'low-confidence ': detection.confidence && detection.confidence < 0.6})}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-id={detection.id}
      ref={ref}
    >
      <div className={'fill'} style={{width: fillWidth}}/>
      {showConfidence && confidence && <span>{Math.ceil(confidence * 100) + '%'}</span>}
    </div>
  )
};

export default DetectionBar;