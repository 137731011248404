import Alert from "../../models/alert";

export const groups = [
  {
    key: 'Process',
    types: ["stairs-late", "gp-late","cargo-late"]
  },
  {
    key: 'Safety',
    types: ["chocks-not-applied",]
  }
]

export const labels : {[key:string]: string | ((i:Alert)=>string)} = {
  "stairs-late": "Airstair has not been connected to arriving aircraft in time. ",
  "gp-late": "GPU has not been connected to arriving aircraft in time. ",
  "chocks-not-applied": "Chocks not applied after aircraft arrival. ",
  "cargo-late": "Baggage / cargo unloading has not commenced in time. "
}