import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {selectReplayTimestamp, selectCurrentTurnaroundId} from "../../redux/selectors";
import {TimelineContext} from "./index";
import {decimalToCSS} from "../../services/dom";
import {now} from "../../services/time";
import CameraOutage from "../../models/cameraOutage";
import {selectBounds} from "./selectors";

type Props = {
  items: CameraOutage[],
  camera: string
}

const OutageRow: React.FC<Props> = ({items,camera})=> {
  const timeline = useContext(TimelineContext);
  const turnId = useSelector(selectCurrentTurnaroundId);
  const replayTs = useSelector(selectReplayTimestamp);
  const bounds = useSelector(selectBounds);
  if(!timeline)
    return null;
  const {getLeftFromTimestamp} = timeline;
  const maxTs = turnId && replayTs ? replayTs : now();
  const bkWidth = turnId ? '100%' : decimalToCSS(getLeftFromTimestamp(maxTs));

  const getFillStyle = (outage: CameraOutage) => {
    let start = Math.max(bounds[0],outage.start);
    let end = outage.end ? Math.min(outage.end, bounds[1]) : maxTs;
    let style = {
      left: decimalToCSS(getLeftFromTimestamp(start)),
      width: decimalToCSS(getLeftFromTimestamp(end) - getLeftFromTimestamp(start))
    };
    return style;
  };

  return (
    <div className={'row outage-row'}>
      <label style={{left:10}}>{camera}</label>
      <div className={'outage-bk'} style={{width:bkWidth}}/>
      {items.map(i=>(
        <div
          className={'outage-fill'}
          style={getFillStyle(i)}
          key={i.id}
        />
      ))}
    </div>
  )
}

export default OutageRow;