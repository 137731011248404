import React, {Component} from 'react';
import {reportError, reportErrorWithTag} from "../../services/logger";
import ErrorMessage from "../ErrorMessage";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    reportErrorWithTag(error,['exception-type','render-failure']);
    if(this.props.onError)
      this.props.onError(error,info);
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  reset() {
    this.setState({hasError:false});
  }

  render() {
    if(this.state.hasError)
      return <ErrorMessage {...this.props}/>;
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}