import React, {Component} from 'react';

import './style.scss';
import {SIDEBAR_VISIBLE_STORAGE_KEY} from "../../services/constants";
import SidebarHeader from "./header";
import SidebarToolbar from "./toolbar";
import SidebarContent from "./content";
import {connect, ConnectedProps} from "react-redux";
import {selectCurrentStandId} from "../../redux/selectors";
import SidebarFooter from "./footer";
import Alert from "../../models/alert";
import {getAlerts} from "../../services/api";
import {updateRate} from "../../services/config";
import {createStructuredSelector} from "reselect";
import { State } from '../../redux/store';
import {minBy} from "lodash";

export const SidebarContext = React.createContext<SidebarContext>(null as any);

type SidebarContext = {
  collapsed: boolean,
  toggle: ()=>void,
  allStands: boolean,
  toggleAllStands: (val: boolean)=>void,
  alerts: Alert[] | null,
  loadMore: ()=>void,
  isLoadingMore: boolean
}

type LocalState = {
  allStands: boolean,
  collapsed: boolean,
  alerts: Alert[] | null,
  until: string | null
}

type ReduxProps = {
  standId?: string
}

const mapStateToProps = createStructuredSelector<State, ReduxProps>({
  standId: selectCurrentStandId
});

const connector = connect(mapStateToProps);

class Sidebar extends Component<ConnectedProps<typeof connector>,LocalState> {
  mounted = true
  isLoadingMore = false
  state = {
    allStands: true,
    collapsed: !!parseInt(localStorage.getItem(SIDEBAR_VISIBLE_STORAGE_KEY) || "0"),
    alerts: null,
    until: null
  }
  timerId?: NodeJS.Timeout

  componentDidMount() {
    this.update();
    this.setState({allStands: !this.props.standId})
  }

  componentWillUnmount() {
    if(this.timerId)
      clearTimeout(this.timerId);
  }

  update = () => {
    getAlerts({until: this.state.until}).then(items=>{
      if(!this.mounted)
        return;
      this.setState({alerts: items})
    }).finally(()=>{
      this.timerId = setTimeout(this.update,updateRate);
    })
  }

  loadMore() {
    if(!this.state.alerts || this.isLoadingMore)
      return;

    this.isLoadingMore = true;

    let oldestAlert: Alert | undefined = minBy(this.state.alerts, a=>a.ts);
    if(!oldestAlert)
      return;

    getAlerts({before:oldestAlert.id}).then(items=> {
      let oldestAlert = minBy(items, a=>a.ts);
      if(oldestAlert)
        this.setState({until: oldestAlert.id});
    }).finally(()=>{
      this.isLoadingMore = false;
    })

    this.forceUpdate();
  }

  toggle = () => {
    const {collapsed} = this.state;
    this.setState({collapsed: !collapsed});
    localStorage.setItem(SIDEBAR_VISIBLE_STORAGE_KEY, collapsed ? "0" : "1")
  }

  render() {
    const {collapsed,alerts,allStands} = this.state;

    const contextValue: SidebarContext = {
      collapsed,
      toggle: this.toggle,
      allStands,
      toggleAllStands: (val: boolean)=>this.setState({allStands: val}),
      alerts,
      loadMore: () => this.loadMore(),
      isLoadingMore: this.isLoadingMore
    }
    if(contextValue.alerts)
      contextValue.alerts = contextValue.alerts.filter(a=>!a.retracted);

    return (
      <SidebarContext.Provider value={contextValue}>
        <SidebarHeader/>
        <SidebarToolbar/>
        <SidebarContent/>
        <SidebarFooter/>
      </SidebarContext.Provider>
    )
  }
}

export default connector(Sidebar);