import React, {
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import classnames from "classnames";

type DropdownContextValue = {
  close: ()=>void;
}
const DropdownContext = React.createContext<DropdownContextValue | null>(null);

function stopPropagation(ev: SyntheticEvent) {
  ev.stopPropagation();
  ev.nativeEvent.stopImmediatePropagation();
};

type Props = {
  className: string;
  title: React.ReactNode;
  align?: string;
}

const Dropdown:React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const {className, children, title, align} = props;
  const [opened,setOpened] = useState(false);
  const [menuStyle, setMenuStyle] = useState<React.CSSProperties>({});
  const btnRef = useRef<HTMLAnchorElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const contextValue = useMemo<DropdownContextValue>(()=>({
    close: ()=>setOpened(false)
  }),[]);
  const onClick = useCallback(()=>contextValue.close(),[]);

  useLayoutEffect(()=>{
    let menu = menuRef.current;
    let main = btnRef.current;
    if(!opened || !menu || !main)
      return;

    let rect = main.getBoundingClientRect();

    let style:React.CSSProperties = {
      top: rect.bottom + 4,
      minWidth: rect.width,
    };
    style.maxHeight = window.innerHeight - 4 - (style.top as number);

    if(menu.offsetWidth > window.innerWidth){
      style.width = window.innerWidth;
      delete style.minWidth;
    }

    if(align === 'left') {
      let left = rect.left;
      if(left + menu.offsetWidth > window.innerWidth)
        style.right = 10;
      else
        style.left = left;
    }
    else {
      let left = rect.right - menu.offsetWidth;
      if(left < 0)
        left = 0;
      style.left = left;
    }
    setMenuStyle(style);

    document.addEventListener('click',onClick);
    return () => {
      document.removeEventListener('click',onClick);
    }
  }, [opened,menuStyle.width, menuStyle.minWidth]);

  return (
    <DropdownContext.Provider value={contextValue}>
      <a
        onClick={()=>setOpened(!opened)}
        className={classnames('dropdown-btn',className)}
        ref={btnRef}
      >
        <span>{title}</span>
        <i className="far fa-chevron-down"/>
      </a>
      {opened && ReactDOM.createPortal(
        <div className={classnames('dropdown-menu',className)} ref={menuRef} style={menuStyle}>
          {children}
        </div>,
        document.getElementById('root') as Element
      )}
    </DropdownContext.Provider>
  );
};

export default Dropdown;

type DropdownItemProps = {
  active?: boolean | null;
  onSelect: (ev:SyntheticEvent)=>void;
  className?: string;
  disableCloseOnClick?: boolean;
  disabled? :boolean
}

export function DropdownItem(props: PropsWithChildren<DropdownItemProps>) {
  const {active,children,onSelect, className, disableCloseOnClick, disabled} = props;
  const {close} = useContext(DropdownContext) as DropdownContextValue;
  const onClick = (ev: SyntheticEvent) => {
    if(disabled){
      stopPropagation(ev);
      return;
    }
    onSelect(ev);
    if(!disableCloseOnClick)
      close();
    else
      stopPropagation(ev);
  }
  return (
    <a className={classnames(className,{active,disabled})} onClick={onClick}>
      {children}
    </a>
  )
}