import 'core-js';
import 'react-app-polyfill/ie11';
import * as Sentry from "@sentry/browser";
import { OidcProvider } from 'redux-oidc';
import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./redux/store";
import userManager from "./services/userManager";
import App from "./components/App";
import { APRON_ENV, SENTRY_DSN } from "./services/config";
import { report } from "./services/logger";
import "./services/debugging";
import "./styles/index.scss";
import lodash from 'lodash';
import './models/global'

window.lodash = lodash;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [],
    environment: APRON_ENV
  });
  report("Application start.");
}

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <App/>
    </OidcProvider>
  </Provider>,
  document.getElementById("root")
);