import React from 'react';
import './style.scss';
import {useDispatch} from "react-redux";
import {TOGGLE_EXPORT_MODAL} from "../../redux/constants";
import StandSelector from "../StandSelector";

const MapToolbar: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <div className={'map-toolbar'}>
      <span className={'overview'}>
        Overview
      </span>

      <StandSelector/>

      <a className={'export'} onClick={()=>dispatch({type: TOGGLE_EXPORT_MODAL})}>Export</a>
    </div>
  )
}

export default MapToolbar;