import {camelCase} from 'lodash';
import {Flight} from "../models/flightInfo";
import Detection from "../models/detection";
import DetectionEvent from "../models/event";

export function camelCaseKeys(obj: any):any {
  return Object.keys(obj).reduce((ccObj, field) => ({
    ...ccObj,
    [camelCase(field)]: obj[field] && typeof obj[field] === 'object'? camelCaseKeys(obj[field]) : obj[field]
  }), {})
}

export function convertObjUtcToMillseconds(obj: any):any {
  for(let key in obj){
    if(typeof obj[key] === 'number')
      obj[key] = obj[key]*1000;
  }
  return obj;
}

export function detectionToEvents(detection: Detection): DetectionEvent[] {
  let res: DetectionEvent[] = [
    {
      id: detection.id + '-start',
      type: detection.startType,
      timestamp: detection.start,
      label: detection.startLabel,
      confidence: detection.startConfidence,
      detectionGap: detection.startDetectionGap,
      detection
    }
  ]

  if(detection.end && detection.endType){
    res.push({
      id: detection.id + '-end',
      type: detection.endType,
      timestamp: detection.end,
      label: detection.endLabel,
      confidence: detection.endConfidence,
      detectionGap: detection.endDetectionGap,
      detection
    })
  }

  return res;
}

export function  formatFlightNumber(info: Flight) {
  let res = '';
  let airline = info.companyIata || info.airline;
  res += airline;
  res += info.flightNumber;
  return res.toUpperCase();
};