import React, {useContext, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectBounds} from "./selectors";
import {formatTime} from "../../services/time";
import {selectCurrentTurnaroundId, selectTimeFormat} from "../../redux/selectors";
import {openFrameModal} from "../../redux/actions";
import {Timeline, TimelineContext} from "./index";
import {now} from "../../services/time";

type Tick = {
  text: string,
  ts: number,
  left: string
}

const TimeAxis : React.FC = ()=> {
  const timeFormat = useSelector(selectTimeFormat);
  const bounds = useSelector(selectBounds);
  const turnId = useSelector(selectCurrentTurnaroundId);
  const [windowWidth,setWindowWidth] = useState(window.innerWidth);
  const {getClickedTimestamp} = useContext(TimelineContext) as Timeline;

  const containerRef = useRef<HTMLDivElement>(null);
  const txtMeasureRef = useRef<HTMLSpanElement>(null);
  const [ticks,setTicks] = useState<Tick[]>([]);
  const dispatch = useDispatch();

  useEffect(()=>{
    const cb = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize',cb);
    return ()=>window.removeEventListener('resize',cb);
  },[]);

  useEffect(()=>{
    if(!containerRef.current || !txtMeasureRef.current)
      return;
    let duration = bounds[1] - bounds[0];
    let axisWidth = containerRef.current.offsetWidth;
    let minTickWidth = txtMeasureRef.current.offsetWidth;
    let min5 = 60 * 5 * 1000;

    let step = (minTickWidth / axisWidth) * duration;
    step = Math.ceil(step / min5) * min5;

    let ts = bounds[0];
    ts = Math.ceil(ts / step) * step;
    let start = bounds[0];

    let ticks = [];
    do {
      ticks.push({
        text: formatTime(ts, false, false),
        ts,
        left: (ts - start) * 100 / duration + '%'
      });
      ts += step;
    } while (ts < bounds[1]);
    setTicks(ticks);
  },[...bounds,timeFormat,windowWidth]);

  const onAxisClick = (ev: React.MouseEvent) => {
    const timestamp = getClickedTimestamp(ev.clientX);
    if(!timestamp || (!turnId && timestamp > now()))
      return;
    dispatch(openFrameModal({timestamp}));
  };

  return (
    <div
      className={'timeaxis'}
      ref={containerRef}
      onClick={(ev) => onAxisClick(ev)}
    >
      <span className={'text-measure tick'} ref={txtMeasureRef}>00:00:00</span>
      {ticks.map(item =>(
        <div className={'tick'} style={{left: item.left}} key={item.ts}>
          <span>{item.text}</span>
        </div>
      ))}
    </div>
  );
}

export default TimeAxis;