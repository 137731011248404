export const ADD_STANDS = "ADD_STANDS";
export const SET_CURRENT_STAND_ID = "SET_CURRENT_STAND_ID";
export const SET_CURRENT_TURNAROUND_ID = "SET_CURRENT_TURNAROUND_ID";
export const SET_CURRENT_PLANE_TYPE = "SET_CURRENT_PLANE_TYPE";
export const SET_TURNAROUNDS = "SET_TURNAROUNDS";
export const RESET_NETWORK_ERRORS = "RESET_NETWORK_ERRORS";
export const SERVER_UNAVAILABLE = "SERVER_UNAVAILABLE";
export const SWITCH_TIME_FORMAT = "SWITCH_TIME_FORMAT";
export const SET_USER = "SET_USER";
export const SET_IMG_TOKEN = "SET_IMG_TOKEN";
export const SET_PUSH_SUBSCRIPTIONS = "SET_PUSH_SUBSCRIPTIONS";
export const TOGGLE_PUSH_SUBSCRIPTIONS_MODAL = "TOGGLE_PUSH_SUBSCRIPTIONS_MODAL";
export const SET_ALARUM_CONNECTED = "SET_ALARUM_CONNECTED";
export const SET_PTS_FOR_PLANE = "SET_PTS_FOR_PLANE";
export const ADD_NOTIFICATIONS = "ADD_NOTIFICATIONS";
export const TOGGLE_EXPORT_MODAL = "TOGGLE_EXPORT_MODAL";

export const UPDATE_REALTIME_DATA = "UPDATE_REALTIME_DATA";
export const SET_SELECTED_TRUNAROUND = "SET_SELECTED_TRUNAROUND";
export const SET_REPLAY_TIMESTAMP = "SET_REPLAY_TIMESTAMP";
export const TOGGLE_CONFIDENCE_ON_TIMELINE = "TOGGLE_CONFIDENCE_ON_TIMELINE";
export const SET_RESOLUTION = "SET_RESOLUTION";
export const OPEN_FRAME_MODAL = "OPEN_FRAME_MODAL";



