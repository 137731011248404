import React from "react";
import {createPortal} from "react-dom";
import TurnaroundInfo from "../TurnaroundInfo";
import Turnaround from "../../models/turnaround";

type Props = {
  target: SVGElement
  turnaround: Turnaround
  standId: string
}

const GatePopup: React.FC<Props> = ({target,turnaround, standId}) => {
  const mapRect = target.closest('svg')?.getBoundingClientRect();
  if(!mapRect)
    return null;
  const rect = target.getBoundingClientRect();
  const style: React.CSSProperties = {
    left: rect.left + rect.width/2
  };
  let placement;

  if(rect.top > mapRect.top + mapRect.height/2) {
    placement = 'top';
    style.bottom = window.innerHeight - rect.top;
  }else {
    placement = 'bottom';
    style.top = rect.top + rect.height;
  }

  const root = document.getElementById('root');
  if(!root)
    return null;

  return createPortal(
    <div className={`gate-popup ${placement}`} style={style}>
      <TurnaroundInfo turnaround={turnaround} standId={standId}/>
    </div>,
    root
  )
}

export default GatePopup;