import {useEffect, useState} from "react";
import {timeline} from "../../services/api";
import {updateRate} from "../../services/config";
import Turnaround from "../../models/turnaround";

type StandStatus = {
  turnaround?: Turnaround,
}

export function useStand(id: string):StandStatus {
  const [status, setStatus] = useState<StandStatus>({});

  useEffect(() => {
    let mounted = true;
    let timerId: NodeJS.Timeout;

    const update = () => {
      timeline(id, Date.now() - 5000, null, ['turnarounds']).then(({turnarounds}) => {
        if (!mounted)
          return;
        let turnaround = turnarounds.find((t:Turnaround) => !t.end);
        setStatus((oldVal)=> ({...oldVal, turnaround}));
      }).finally(() => {
        if (!mounted)
          return;
        setTimeout(update, updateRate);
      });
    };
    update();

    return () => {
      mounted = false;
      clearTimeout(timerId)
    };
  }, [id]);

  return status;
}