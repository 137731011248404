import React, {Component} from 'react';
import "./style.scss"
import classNames from "classnames";

type Props = {
  className? : string
}

const Spinner: React.FC<Props> = ({className}) =>{
  return (
    <div className={classNames("spinner",className)}>
    </div>
  )
}

export default Spinner;