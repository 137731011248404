import React, {PropsWithChildren, useEffect, useState} from "react";
import Loader from "../Spinner";
import FrameContainer from '../FrameContainer';
import {loadImageAjax} from "../../services/images";

type Props = {
  timestamp: number;
  camera: string;
  onSuccess?: ()=>void;
  onFail: ()=>void;
  disableSpinner?: boolean;
  className?: string
}

const Frame : React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const {timestamp,camera,children, onSuccess, onFail, disableSpinner, className} = props;
  const [loading,setLoading] = useState(true);
  const [url,setUrl] = useState<string>("");
  const [success,setSuccess] = useState(false);

  useEffect(()=>{
    let cancelFlag = false;
    setLoading(true);
    loadImageAjax(camera,timestamp).then(url=>{
      if(cancelFlag)
        return;
      setUrl(url);
      setLoading(false);
      setSuccess(true);
      onSuccess && onSuccess();
    },()=>onFail());
    return () => {cancelFlag = true}
  },[timestamp,camera]);

  return <FrameContainer camera={camera} className={className}>
    {!disableSpinner && loading && <Loader/>}
    {url && (
      <img
        src={url}
        className={'frame'}
        alt=''
        style={{visibility: success ? 'visible':'hidden'}}
      />
    )}
    {children}
  </FrameContainer>
};

export default Frame;
