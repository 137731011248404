import {useSelector} from "react-redux";
import {selectCurrentTurnaround, selectPtsForCurrentPlaneType} from "../../redux/selectors";
import {PTS_MULTIPLIER} from "./selectors";

import React, {useContext} from "react";
import {Timeline, TimelineContext} from "./index";

type Props = {
  type: string
}

const PtsBar:React.FC<Props> = ({type}) => {
  const pts = useSelector(selectPtsForCurrentPlaneType);
  const turnaround = useSelector(selectCurrentTurnaround);
  const timeline = useContext<Timeline | null>(TimelineContext);
  if(!turnaround || !timeline)
    return null;

  const {getLeftFromTimestamp} = timeline;
  const turnEnd = turnaround.start + pts.duration*PTS_MULTIPLIER;


    let scheduledStart = pts[type][1] > 0
      ? turnaround.start + pts[type][0]*PTS_MULTIPLIER
      : turnEnd + pts[type][0]*PTS_MULTIPLIER;
  let scheduledEnd = pts[type][1] > 0
      ? turnaround.start + pts[type][1]*PTS_MULTIPLIER
      : turnEnd + pts[type][1]*PTS_MULTIPLIER;

  const left = getLeftFromTimestamp(scheduledStart)*100 + '%';
  const width = (getLeftFromTimestamp(scheduledEnd) - getLeftFromTimestamp(scheduledStart))*100 +'%';

  return (
    <div style={{left,width}} className={'pts-bar'}/>
  )
};

export default PtsBar;

// const pts = useSelector(selectPtsForCurrentPlaneType);
// let ptsBar = null;
// if(pts && pts[items[0].type]) {
//   let operationSchedule = pts[items[0].type];
//   let turnEnd = turnaround.start + pts.duration*PTS_MULTIPLIER;
//   let scheduledStart =
//     operationSchedule[0] > 0
//       ? bounds[0] + operationSchedule[0]*PTS_MULTIPLIER
//       : turnEnd + operationSchedule[0]*PTS_MULTIPLIER;
//   let scheduledEnd =
//     operationSchedule[1] > 0
//       ? bounds[0] + operationSchedule[1]*PTS_MULTIPLIER
//       : turnEnd + operationSchedule[1]*PTS_MULTIPLIER
// }