import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './style.scss';
import ContactForm from "./form";

export default function MobileFormPanel() {
  const [collapsed, setCollapsed] = useState(true);

  useEffect(()=>{
    setTimeout(()=>setCollapsed(false),15000)
  },[]);

  if(!collapsed)
    return (
      <div className={'mobile-contact-form'}>
        <a className={'fal fa-times close-btn'} onClick={()=>setCollapsed(true)}/>
        <ContactForm onSuccess={()=>setCollapsed(true)}/>
      </div>
    );

  return (
    <a className={'mobile-contact-btn'} onClick={()=>setCollapsed(!collapsed)}>
      CONTACT
    </a>
  )
}