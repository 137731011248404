import React, {useContext} from 'react';
import './style.scss';
import {SidebarContext} from "./index";

const SidebarHeader: React.FC = ()=> {
  const {collapsed,toggle} = useContext(SidebarContext);

  if(collapsed)
    return (
      <div className={'sidebar_header sidebar_component collapsed'} onClick={toggle}>
        <i className="fas fa-bell"/>
      </div>
    );

  return (
    <div className={'sidebar_header'}>
      <i className="fas fa-bell"/>
      <h2>Notifications</h2>
      <a onClick={toggle}>
        <i className={'fal fa-angle-double-left'}/>
      </a>
    </div>
  )
};

export default SidebarHeader;