import {detect} from "detect-browser";

export const browser = detect();

export const isWindows =  browser?.os?.toLowerCase().indexOf('windows') !== -1;

export const isIOS = browser?.os?.toLowerCase().indexOf('ios') !== -1;

export const isDesktopSafari = (
  browser?.os?.toLowerCase().indexOf('mac') !== -1 &&
  browser?.name.toLowerCase().indexOf('safari') !== -1
);

export const isIE11 = browser?.name?.toLowerCase().indexOf('ie') !== -1;

export const isEdge = browser?.name?.toLowerCase().indexOf('edge') !== -1;

const mobileWidth = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--mobile-width').replace('px',''));
export const isMobile = window.innerWidth <= mobileWidth;