import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import thunk from 'redux-thunk';
import { reducer as oidcReducer } from 'redux-oidc';
// @ts-ignore
import { createLogger } from 'redux-logger'
import { connectRouter, routerMiddleware } from 'connected-react-router'

import realtimeReducer from './realtimeReducer';
import appReducer from './appReducer';
import {history} from "../services/router";

const logger = createLogger({
  predicate: ()=>false && true,
  collapsed:process.env.NODE_ENV === 'development',
});

const reducer = combineReducers({
  realtime :realtimeReducer,
  app: appReducer,
  user: oidcReducer,
  router: connectRouter(history),
});

const store = createStore(
    reducer,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        thunk,
        logger
      ),
    ),
);

export type State = ReturnType<typeof reducer>;

window._store = store;

export default store;