import React from "react";
import TurnaroundInfo from "./index";
import {useSelector} from "react-redux";
import {selectCurrentTurnaround} from "../../redux/selectors";

const CurrentTurnaroundInfo: React.FC = () => {
  const turnaround = useSelector(selectCurrentTurnaround);

  if(!turnaround)
    return null;
  return <TurnaroundInfo turnaround={turnaround}/>
};

export default CurrentTurnaroundInfo;