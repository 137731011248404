import Group from "./group";
import {Store} from "redux";
import {State} from "../redux/store";

declare global {
  interface Window {
    lodash: any;
    groupsMeta: Group[],
    operationsLabels: {[key:string]:string}
    eventsLabels: {[key:string]:string}
    mergeableOperations: {[key:string]:string[]},
    hiddenOperations: string[]
    _store: Store<State>
    appConfig: {[key:string]:any}
    devConfig: {[key:string]:any}
  }
}

export default Window;