import * as Sentry from '@sentry/browser';

export function reportError(error: Error) {
  console.error(error);
  Sentry.captureException(error);
}

export function reportErrorWithTag(error:Error,[tagName,tagValue]: [string,any]) {
  Sentry.withScope(function(scope) {
    scope.setTag(tagName,tagValue);
    Sentry.captureException(error);
  });
}

// @ts-ignore
window._reportErrorWithTag = reportErrorWithTag;

export function report(msg: string) {
  Sentry.captureMessage(msg);
}