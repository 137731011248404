import {createSelector} from "reselect";
import {flatten, maxBy, orderBy,difference} from "lodash";
import {camerasOrder, enablePts, filterCameras, frozen, redWarning} from "../services/config";
import {now} from "../services/time";
import {detectionToEvents} from "../services/data";
import { State } from "./store";

const selectAppState = (state:State) => state.app;
const selectUserState = (state:State) => state.user;
export const selectRealtimeState = (state:State) => state.realtime;

export const selectStands = createSelector(selectAppState,app=>app.stands);

export const selectCurrentStandId = createSelector(selectAppState,state=>state.currentStandId);

export const selectCurrentStand = createSelector(selectStands,selectCurrentStandId,
  (stands,id)=>stands.find(s=>s.id === id));

export const selectCameras = createSelector(selectCurrentStand,(stand)=>{
  if(!stand)
    return [];

  let cameras: string[] = [];
  camerasOrder.forEach((key:string)=>{
    let cam = stand.cameras.find(c=>c.indexOf(key)!==-1);
    if(cam)
      cameras.push(cam);
  });
  cameras.push(...difference(stand.cameras,cameras).sort());

  if(filterCameras){
    let filter = Array.isArray(filterCameras) ? filterCameras : filterCameras[stand.id];
    if(filter)
      cameras = cameras.filter(c=>!filter.some((f:string)=>c.indexOf(f)!==-1));
  }

  return cameras;
});

export const selectTurnarounds = createSelector(
  selectRealtimeState,
  selectCurrentStandId,
  ({turnarounds})=>{
    turnarounds = orderBy(turnarounds,['start'],['desc']);
    turnarounds = turnarounds.filter((turn, index) => !index || turn.end);
    turnarounds = turnarounds.filter(t => !t.end || t.end - t.start >= 60000);
    if(frozen) {
      turnarounds = turnarounds.filter(t => t.end);
    }
    return turnarounds;
  }
);

export const selectAllTurnaroundsLoaded = createSelector(selectRealtimeState,state=>state.allTurnaroundsLoaded);

export const selectAllowedTurnarounds = createSelector(selectTurnarounds,turns=>(turns || []).filter(t=>!t.end || t.authorized));

export const selectCurrentTurnaroundId = createSelector(selectRealtimeState, state=>state.currentTurnaroundId);

export const selectCurrentTurnaround = createSelector(selectTurnarounds,selectCurrentTurnaroundId, (turns, id)=>{
  if (!turns || !turns.length) {
    return null;
  }
  if(id)
    return turns.find(t=>t.id === id) || null;
  let turn = maxBy(turns,'start');
  return turn && turn.end ? null : turn;
});

export const selectAllDetections = createSelector(selectRealtimeState, state=>state.detections);

export const selectDetections = createSelector(selectAllDetections,selectCurrentTurnaround, (detections, turnaround)=>{
  if(!turnaround || !turnaround.authorized)
    return [];

  let {start} = turnaround;
  const end = turnaround.end || now();

  detections = detections.filter(op=>op.end
    ? op.start <= end && op.end >= start
    : op.start <= end);

  return detections;
});

export const selectEvents = createSelector(selectDetections, detections=>{
  let events = detections.map(detectionToEvents);
  return flatten(events);
});

export const selectFrameModalData = createSelector(selectRealtimeState,state=>state.frameModalData);

export const selectInferenceTimestamp = createSelector(selectRealtimeState, state=> state.inferenceTimestamp);

export const selectLastImageTimestamp = createSelector(selectRealtimeState,selectInferenceTimestamp,
  (state,inferenceTs)=> state.lastImageTimestamp || inferenceTs);

export const selectReplayTimestamp = createSelector(selectRealtimeState, state=> state.replayTimestamp);

export const selectResolution = createSelector(selectRealtimeState, state=>state.resolution);

export const selectAspectRatio = createSelector(selectResolution, resolution => {
  let result : {[key:string]:number} = {};
  for(let cam in resolution)
    result[cam] = resolution[cam][0]/resolution[cam][1];
  return result;
});

export const selectPlaneType = createSelector(selectRealtimeState, state=>state.planeType);

export const selectColors = createSelector(selectRealtimeState, state=>state.colors);

export const selectTimeFormat = createSelector(selectAppState, state=>state.timeFormat);

export const selectServerUnavailable = createSelector(selectAppState,state=>state.serverUnavailable);

export const selectUser = createSelector(selectUserState,state=>(<any>{profile:{email:'test.com'}}));

export const selectImgToken = createSelector(selectAppState,state=>state.imgToken);

export const selectGetImageUrl = createSelector(selectImgToken,selectTurnarounds,selectCurrentStandId,
  (token,turnarounds,standId) => (camera: string, ts: number) => {
    if(!turnarounds)
      return '';
    let turn = turnarounds.find(t=>t.start<=ts && (!t.end || t.end>=ts));
    ts = Math.floor(ts / 1000);
    if(turn && turn.videos[camera]){
      ts = ts - Math.floor(turn.start / 1000);
      return `/replay_frame/${camera}/${turn.id}?at=${ts}&token=${token}`;
    }
    return `/images/${standId}/${camera}/${ts}.jpg?token=${token}`;
  });

export const selectShowPushSettingsModal = createSelector(selectAppState, state=>state.showPushSettingsModal);

export const selectShowExportModal = createSelector(selectAppState,state=>state.showExportModal);

export const selectAuthToken = createSelector(selectUser,(user)=>user && user.id_token);

export const selectAuthHeader = createSelector(selectAuthToken,(token)=>'JWT ' + token);

export const selectPlaneTypes = createSelector(selectRealtimeState,state=>Object.keys(state.pts));

export const selectPtsForCurrentPlaneType = createSelector(
  selectRealtimeState,
  selectPlaneType,
  ({pts}, type)=>type && pts[type]);

export const selectPtsEnabled = createSelector(selectRealtimeState,state=>enablePts && !!Object.keys(state.pts).length);

export const selectShowConfidenceOnTimeline = createSelector(selectRealtimeState, s=>s.showConfidenceOnTimeline);

export const selectStandWasNotClearAtArrival = createSelector(selectCurrentTurnaround,selectAllDetections,
  (turn, detections)=>{
    if(!detections || !turn)
      return null;

    let ts =turn?.inboundFlight?.actualLandingTime ||  turn.start;
    return detections.find(d=>
      d.type === 'stand_not_clear'
      && d.start <=ts
      && (!d.end || d.end >=ts)
    );
  }
);

export const selectTopAlert = createSelector(selectCurrentStandId,(id) => {
  if(!redWarning)
    return null;
  if(!id) {
    if(typeof redWarning === 'string')
      return redWarning;
    else
      return redWarning.default;
  }

  if(typeof redWarning === 'string')
    return redWarning;
  else
    return redWarning[id] || redWarning.default;
})