import React from 'react';
import {useDispatch, useSelector} from "react-redux";

import {
  selectCurrentTurnaround,
  selectStandWasNotClearAtArrival
} from "../../redux/selectors";
import {
  openFrameModal
} from "../../redux/actions";
import "./style.scss";
import {showStandClearAlert} from "../../services/config";

const TurnaroundAlerts: React.FC = () => {
  const notClear = useSelector(selectStandWasNotClearAtArrival);
  const turnaround = useSelector(selectCurrentTurnaround);
  const dispatch = useDispatch();

  if(showStandClearAlert && turnaround && notClear)
    return (
      <div className={'turnaround-alert'}>
        <i className="far fa-exclamation-triangle"/>&nbsp;
        Stand was not clear at the&nbsp;
        <a onClick={()=>dispatch(openFrameModal({timestamp:turnaround.start}))}>moment</a>&nbsp;
        of arrival.
      </div>
    );

  return null;
}

export default TurnaroundAlerts;