import React, {Component} from 'react';
import {createStructuredSelector} from "reselect";
import {connect, ConnectedProps} from "react-redux";
import {orderBy} from 'lodash';

import './style.scss';
import {
  selectCameras,
  selectCurrentTurnaround,
  selectShowConfidenceOnTimeline,
  selectTimeFormat,
  selectCurrentTurnaroundId,
} from "../../redux/selectors";
import {DetectionEvent, selectBounds, selectCameraOutages, selectTimelineData, selectTimelineTimestamp} from "./selectors";
import {openFrameModal, setReplayTimestamp} from "../../redux/actions";
import TimeAxis from "./TimeAxis";
import OperationRow from "./OperationRow";
import TimeDashLine from "./TimeDashLine";
import OutageRow from "./OutageRow";
import {State} from "../../redux/store";
import CameraOutage from "../../models/cameraOutage";
import Turnaround from "../../models/turnaround";
import {ThunkDispatch} from "redux-thunk";
import {Action} from "redux";
import {FrameModalData} from "../../models/common";
import Detection from "../../models/detection";

export const TimelineContext  = React.createContext<Timeline | null>(null);

type ReduxProps = {
  showConfidence?: boolean,
  timeFormat: string,
  bounds: [number,number],
  groups: ReturnType<typeof selectTimelineData>,
  outages: CameraOutage[],
  currentTime?: number,
  cameras: string[],
  turnaround?: Turnaround | null,
  turnaroundId?: string
}

const mapStateToProps = createStructuredSelector<State,ReduxProps>({
  showConfidence: selectShowConfidenceOnTimeline,
  timeFormat: selectTimeFormat,
  bounds: selectBounds,
  groups: selectTimelineData,
  outages: selectCameraOutages,
  currentTime: selectTimelineTimestamp,
  cameras: selectCameras,
  turnaround: selectCurrentTurnaround,
  turnaroundId: selectCurrentTurnaroundId
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State,any,Action>) => ({
  openFrameModal: (data:FrameModalData) => dispatch(openFrameModal(data)),
  setTimestamp: (ts:number)=> dispatch(setReplayTimestamp(ts))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

type TimelineState = {
  tmpTime?: number | null
}

export class Timeline extends Component<Props, TimelineState> {
  ref = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {};

    this.getLeftFromTimestamp = this.getLeftFromTimestamp.bind(this);
    this.getClickedTimestamp = this.getClickedTimestamp.bind(this);
  }

  getClickedTimestamp(x:number) {
    const [start,end] = this.props.bounds;
    let rect = this.ref.current?.querySelector('.col2')?.getBoundingClientRect();
    if(!rect)
      return null;
    let left = (x - rect.left) / rect.width;
    let ts = start + (end - start) * left;
    return ts;
  }

  getLeftFromTimestamp(ts: number) {
    const {bounds} = this.props;
    return (ts - bounds[0])/(bounds[1] - bounds[0]);
  }

  onPaneClick(ev: React.MouseEvent) {
    if(this.props.turnaroundId){
      let ts = this.getClickedTimestamp(ev.clientX);
      if(ts)
        this.props.setTimestamp(ts);
    }
  }

  onPaneMove(ev: React.MouseEvent) {
    let ts = this.getClickedTimestamp(ev.clientX);
    this.setState({tmpTime: ts})
  }

  renderOperationGroup(title: string,rows: (Detection | DetectionEvent)[][],index: number) {
    rows = orderBy(rows,[r=>r[0].type.toLowerCase()],['asc']);
    return this.renderGroup(title,rows.map((items) => <OperationRow items={items} key={items[0].type}/>),index)
  }

  renderOutagesGroup() {
    let {outages, cameras} = this.props;
    const subgroups : ([string,CameraOutage[]])[] = cameras.map(c=>[c,outages.filter(o=>o.camera === c)]);

    return this.renderGroup(
      'Cameras availability',
      subgroups.map(([camera,items]) => <OutageRow items={items} key={camera} camera={camera}/>),
      1
    )
  }

  renderGroup(title: string,rows: React.ReactNode[], index: number) {
    if(!rows.length)
      return null;
    //IE11 needs gridRow
    const style = {gridRow:index+1,msGridRow:index+1};
    return (
      <React.Fragment key={title}>
        <div className={'group-name col1'} style={style}>
          {title}
        </div>
        <div
          className={'col2 pane'}
          style={style}
          onMouseMove={ev => this.onPaneMove(ev)}
          onMouseLeave={() => this.setState({tmpTime: null})}
          onClick={(ev) => this.onPaneClick(ev)}
        >
          {rows}
        </div>
      </React.Fragment>
    )
  }

  render() {
    let {groups,currentTime,outages,turnaround} = this.props;
    let {tmpTime} = this.state;

    return (
      <div
        className={'timeline'}
        ref={this.ref}
      >
        <TimelineContext.Provider value={this}>
          {turnaround && !!tmpTime && <TimeDashLine timestamp={tmpTime} className={'tmp-time'}/>}
          {turnaround && currentTime && <TimeDashLine timestamp={currentTime} className={'current-time'} showTimeLabel={true}/>}
          <div className={'col1 bk-top'}/>
          <div
            className={'col2 bk-top'}
            onMouseMove={ev => this.onPaneMove(ev)}
            onMouseLeave={() => this.setState({tmpTime: null})}
          >
            <TimeAxis/>
          </div>
          {!!outages.length && this.renderOutagesGroup()}
          {groups.map(([title, rows],index) =>this.renderOperationGroup(title,rows,outages.length ? index + 2 : index + 1))}
        </TimelineContext.Provider>
      </div>
    );
  }
}

export default connector(Timeline);
