import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {TimelineContext} from "./index";
import {useSelector} from "react-redux";
import classnames from 'classnames';

import {selectBounds} from "./selectors";
import {formatTime} from "../../services/time";

type Props = {
  timestamp: number;
  className: string;
  showTimeLabel?: boolean;
}

const TimeDashLine: React.FC<Props> = ({timestamp,className, showTimeLabel}) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);
  const [start,end] = useSelector(selectBounds);
  const timeline = useContext(TimelineContext);
  const [left,setLeft] = useState<string | undefined>();

  useEffect(()=>{
    const timelineEl = timeline?.ref?.current;
    if(!timelineEl)
      return;
    const col1El = timelineEl.querySelector('.col1');
    const col2El = timelineEl.querySelector('.col2');
    if(!col1El || !col2El)
      return;

    let labelsSectionWidth = col1El.getBoundingClientRect().width;
    let paneWidth = col2El.getBoundingClientRect().width;
    let newLeft = labelsSectionWidth + paneWidth * (timestamp - start)/(end-start) + 'px';
    setLeft(newLeft);
  },[timestamp]);

  useLayoutEffect(()=>{
    if(!spanRef.current || !lineRef.current)
      return;

    if(showTimeLabel) {
      const axis = document.querySelector('.timeaxis');
      if(!axis)
        return;
      const axisRect = axis.getBoundingClientRect();
      const lineRect = lineRef.current.getBoundingClientRect();

      if(lineRect.left - axisRect.left > 100){
        spanRef.current.style.transform = `translate(-100%)`
      }
    }
  },[left]);

  return <>
    <div
      className={classnames('time-dashline',className)}
      ref={lineRef}
      style={{left}}
    >
      {showTimeLabel && (
        <span className={'time-label'} ref={spanRef} key={timestamp}>
          {formatTime(timestamp, false)}
        </span>
      )}
    </div>
  </>
};

export default TimeDashLine;