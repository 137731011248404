import React, {Component} from 'react';
import {ERROR_MESSAGE} from "../../services/constants";
import './style.scss';

type Props = {
  msg?: string,
  showRefreshBtn?: boolean
}

const ErrorMessage:React.FC<Props> = ({msg,showRefreshBtn = true}) => {
  return (
    <div className={'error-message'}>
      <i className="far fa-broadcast-tower"/>
      <h1>{msg || ERROR_MESSAGE}</h1>
      {showRefreshBtn && (
        <a onClick={()=>window.location.reload()}>
          <i className="far fa-sync-alt"/>
          retry
        </a>
      )}
    </div>
  )
};

export default ErrorMessage;